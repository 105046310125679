import { App, reactive, readonly } from 'vue';

export interface Snack {
    type: "success" | "error" | "warn" | "info";
    msg: string;
}

export interface Toast {
    bgColor?: string;
    msg: string;
    icon?: any;
}

class AppState {
    private snacks: Snack[] = reactive([]);
    private toasts: Toast[] = reactive([]);

    public snack(snack: Snack) {
        this.snacks.push(snack);
    }
    public get currentSnack() {
        if (this.snacks.length == 0) {
            return null;
        }
        return readonly(this.snacks[this.snacks.length - 1]);
    }

    public toast(toast: Toast) {
        this.toasts.push(toast);
    }
    public get currentToast() {
        if (this.toasts.length == 0) {
            return null;
        }
        return readonly(this.toasts[this.toasts.length - 1]);
    }
}
export default new AppState();