<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    width="32"
    height="32"
  >
    <defs>
      <clipPath id="clip-line">
        <path
          d="M319.3 843.12h170.64a30.57 30.57 0 0 0 21.62-52.18l-35.82-35.81a19.5 19.5 0 0 1 4.05-30.66l128.76-74.22a5.55 5.55 0 0 0-5.54-9.61l-128.75 74.23a30.57 30.57 0 0 0-6.35 48.1l35.81 35.81A19.49 19.49 0 0 1 489.94 832H320z"
        />
      </clipPath>
    </defs>
    <g clip-path="url(#clip-line)">
      <path
        id="line-path"
        d="M605.5 645.5l-132 76c-10 6-15 26 0 40l38 38c10 11 0 38-20 38h-172"
        fill="none"
        :stroke="iconColor"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-width="13"
        stroke-dasharray="476"
        stroke-dashoffset="476"
        style="animation:drawInPath 2.2s ease-in-out 0s infinite"
      />
    </g>
    <path
      id="pen"
      d="M319.3 843.12c.06-3.54.16-7.25.28-11.08 1.64-51.67 8.77-127.38 18.28-171.37a742.08 742.08 0 0 1 293.49-447.82q-18 10.74-35.24 22.42a741.13 741.13 0 0 0-253.5 294.91l-35.89-89.26a740 740 0 0 1 122-148l11.37 27.82 45.73-76a749.53 749.53 0 0 1 300.7-136.66h.19a3.82 3.82 0 0 1 2.9 6.9q-19.3 13.31-37.57 27.69-14.47 11.33-28.29 23.42A743 743 0 0 0 527.91 436.5l-49.37 35.79 30.51 13.5a739.37 739.37 0 0 0-30 118.1l-110.46 44.42c-6.89 37.43-12 95.67-13.64 145.83A111.26 111.26 0 0 0 324.14 832c-1.25 2.91-2.97 6.85-4.84 11.12z"
      :fill="iconColor"
      style="transform-origin:319.3px 843.12px;animation:movePen 2.2s linear 0s infinite"
    />
  </svg>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  name: "LogoIcon",
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "black",
    },
    animate: {
      type: Boolean,
      default: false,
    },
  },
})
export default class LogoIcon extends Vue {
  //https://github.com/sdras/vue-sample-svg-icons/blob/master/src/components/IconBase.vue
}
</script>
<style lang="css" scoped>
@-webkit-keyframes movePen {
  0% {
    transform: translate(0, 0) rotate(0deg);
    animation-timing-function: ease-in-out;
  }

  17.86% {
    transform: translate(285px, -191px) rotate(-15deg) scale(0.7, 0.7);
    animation-timing-function: cubic-bezier(0.12, 0, 0.43, 0);
  }

  32.14% {
    transform: translate(143px, -110px) rotate(-5deg) scale(0.91, 0.91);
  }

  33.04% {
    -webkit-transform: translate(143px, -90px) rotate(-5deg) scale(0.93, 0.93);
    transform: translate(143px, -90px) rotate(-5deg) scale(0.93, 0.93);
  }

  36.25% {
    transform: translate(192px, -35px) rotate(-5deg) scale(0.97, 0.97);
  }

  38.04% {
    transform: translate(180px, 0) rotate(-5deg) scale(1, 1);
    animation-timing-function: ease-out;
  }

  51.25% {
    transform: translate(0, 0) rotate(-5deg);
    animation-timing-function: ease-out;
  }

  60.71%,
  to {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes movePen {
  0% {
    transform: translate(0, 0) rotate(0deg);
    animation-timing-function: ease-in-out;
  }

  17.86% {
    transform: translate(285px, -191px) rotate(-15deg) scale(0.7, 0.7);
    animation-timing-function: cubic-bezier(0.12, 0, 0.43, 0);
  }

  32.14% {
    transform: translate(143px, -110px) rotate(-5deg) scale(0.91, 0.91);
  }

  33.04% {
    transform: translate(143px, -90px) rotate(-5deg) scale(0.93, 0.93);
  }

  36.25% {
    transform: translate(192px, -35px) rotate(-5deg) scale(0.97, 0.97);
  }

  38.04% {
    transform: translate(180px, 0) rotate(-5deg) scale(1, 1);
    animation-timing-function: ease-out;
  }

  51.25% {
    transform: translate(0, 0) rotate(-5deg);
    animation-timing-function: ease-out;
  }

  60.71%,
  to {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes drawInPath {
  0%,
  17.86% {
    opacity: 1;
    stroke-dashoffset: 476px;
  }

  53.57%,
  82% {
    opacity: 1;
    stroke-dashoffset: 0;
  }

  97%,
  to {
    opacity: 0;
    stroke-dashoffset: 0;
  }
}
</style>