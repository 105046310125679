<template>
  <sticky-page>
    <div class="card bg-transparent" data-card-height="cover">
      <div class="card-center text-center">
        <i class="fa fa-exclamation-triangle fa-8x color-red2-dark"></i>
        <h1 class="fa-6x mt-5 font-900 color-gray1-light">404</h1>
        <h4 class="mb-5 mt-4 color-gray1-light">{{resource}} not Found</h4>
        <p class="color-gray1-light">The {{resource}} you're looking for cannot be found.<br /></p>

        <div class="row ml-5 mr-5 mt-5 mb-0 justify-content-center">
          <div class="col-md-6 col-lg-4 mb-3">
            <router-link
              to="/"
              class="btn btn-m rounded-sm btn-full bg-red2-dark text-uppercase font-900"
              >Back Home</router-link
            >
          </div>
          <div class="col-md-6 col-lg-4 mb-3">
            <router-link
              to="/contact"
              class="btn btn-m rounded-sm btn-full bg-red2-dark text-uppercase font-900"
              >contact</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </sticky-page>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import StickyPage from "@/components/sticky-page.vue";
@Options({
  name: "NotFoundView",
  components: {
    StickyPage,
  },
  props:{
    resource:{
      type: String,
      required: true,
      default: 'unknown'
    }
  }
})
export default class NotFound extends Vue {
  //https://github.com/sdras/vue-sample-svg-icons/blob/master/src/components/IconBase.vue
}
</script>
