import APIService from './apiService';

const settingsKey = "profile.settings";
export class ProfileSettings {
    project = {
        allowPause: false,
        useGeoposition: false
    };
    theme = {
        darkMode: false,
        bgColor: '',
        highlightColor: ''
    };
    general = {
        debugMode: false
    };
}
export class ProfileService {
    private apiService = new APIService();
    public async getCurrentSettings() {
        const val = localStorage.getItem(settingsKey);
        if (val) { //TODO: some kind of merge promote to latest version of settings if needed
            return JSON.parse(val) as ProfileSettings;
        }
        return new ProfileSettings();
    }
    public async replaceSettings(newSettings: ProfileSettings) {
        localStorage.setItem(settingsKey, JSON.stringify(newSettings));
        this.apiService.queueItem('/profile/settings', newSettings);
    }
}
