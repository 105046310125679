<template>
  <div
    v-if="toast"
    ref="toastRef"
    id="toast-processing"
    class="toast toast-tiny toast-top bg-blue2-dark"
    data-delay="3000"
    data-autohide="true"
  >
    <i class="mr-3"><fa icon="sync" spin /></i>{{ toast.msg }}
  </div>
</template>
<script lang="ts">
import { Toast } from "bootstrap";
import { defineComponent, computed, ref, Ref } from "vue";
import AppState from "@/shared/appState";

export default defineComponent({
 setup(props, { attrs, slots , emit }) {
    const toastRef = (ref(null) as any) as Ref<Element>;
    const toast = computed((ctx) => {
      const r = AppState.currentToast;
      if (r && toastRef?.value) {
        const notificationToast = new Toast(toastRef.value);
        notificationToast.show();
      }
      return r;
    });
    return {
      toast,
      toastRef,
    };
  },
  name: "StickyToast",
  props: {
    msg: String,
  },
  methods: {},
});
</script>