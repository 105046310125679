import { LogService } from '@/services/logService';
//TODO: this is hacky rethink this
export class GlobalWatch {
    private appInstalled = false;
    private ls = new LogService();
    public async init() {
        window.addEventListener("error", e => this.onError(e));
        window.addEventListener("beforeinstallprompt ", e => this.onBeforeInstall(e));
        window.addEventListener("appinstalled ", e => this.onInstall(e));
        window.addEventListener('DOMContentLoaded', e => this.onLaunch(e));

        if (window.navigator.getInstalledRelatedApps) {
            const installedApps = await window.navigator.getInstalledRelatedApps();
            installedApps.forEach((app) => {
                this.ls.info(`${app.id}, ${app.platform}, ${app.url}`);
            });
        }
    }

    private async onLaunch(e: Event) {
        let displayMode = 'browser tab';
        if ((navigator as any).standalone) {
            displayMode = 'standalone-ios';
        }
        if (window.matchMedia('(display-mode: standalone)').matches) {
            displayMode = 'standalone';
        }
        // Log launch display mode to analytics
        this.ls.info('DISPLAY_MODE_LAUNCH:' + displayMode);
        window.ww.displayMode = displayMode;
    }
    private async onError(e: ErrorEvent) {
        const stack = e.error?.stack;
        let message = e.message || e.error?.message || e.error?.toString();

        if (stack) {
            message += "\n" + stack;
        }

        this.ls.error(message, e);
    }

    private async onBeforeInstall(e: Event) {
        e.preventDefault();
        //TODO: show PWA install event
    }
    private async onInstall(e: Event) {
        this.ls.info('App Installed on Chrome!');
    }
}
