
import $ from "jquery";
import { WWUser } from "@/shared/user";
import { ProfileService, ProfileSettings } from "@/services/profileService";
import UserService from "@/services/userService";
import { defineComponent } from '@vue/runtime-core';

const xps = new ProfileService();
const xprefs = new ProfileSettings();

export default defineComponent({
  name: "MainMenu",
  data() {
    return {
      us: UserService,
      registerUrl: "",
      watchInt: 0,
      showSpinner: true,
      spinMsg: "word to your mother",
      ps: xps as ProfileService,
      prefs: xprefs as ProfileSettings
    };
  },
  computed: {
    showDebug():boolean {
      return this.prefs?.general.debugMode || false;
    },
    showLogin():boolean {
      return this.user.signInState == "Unknown";
    },
    user(): WWUser {
      return this.us.currentUser;
    },
  },
  async created() {
    this.prefs = await this.ps.getCurrentSettings();
  },

  async beforeUnmount() {
    $(this.$el).hideMenu();
    console.log("unmount");
  },
  methods: {
    logout() {
      console.log("logout j/k");
    },
  },
});
