<template>
  <div
    id="main-menu"
    class="bg-white menu menu-box-left"
    data-menu-width="320"
    data-menu-effect="menu-over"
  >
    <div class="pl-3 pr-3 pt-3 mt-4 mb-2">
      <router-link to="/profile">
        <div class="d-flex">
          <div class="mr-3">
            <img
              :src="user.avatarUri || '/images/pictures/faces/1s.png'"
              class="mr-3 rounded-circle shadow-l"
              width="43"
              height="43"
            />
          </div>
          <div class="flex-grow-1">
            <h1 class="font-22 font-700 mb-0">{{ user.displayName }}</h1>
            <p class="mt-n2 font-10 font-400">{{ user.tagLine }}</p>
          </div>
        </div>
      </router-link>
    </div>

    <div class="mr-3 ml-3">
      <span class="text-uppercase font-900 font-11 opacity-30">Navigation</span>
      <div class="list-group list-custom-small">
        <router-link to="/">
          <i class="font-14 rounded-s bg-yellow1-dark"><fa icon="star" /></i>
          <span>Home</span>
          <i><fa icon="angle-right" /></i>
        </router-link>
        <router-link to="/projects">
          <i class="font-14 rounded-s bg-green1-dark"><fa icon="books" /></i>
          <span>Projects</span>
          <!-- <span class="badge bg-red2-dark">NEW</span> -->
          <i><fa icon="angle-right" /></i>
        </router-link>
        <router-link to="/contact">
          <i class="font-14 rounded-s bg-red2-dark"><fa icon="envelope" /></i>
          <span>Contact</span>
          <i><fa icon="angle-right" /></i>
        </router-link>
        <router-link to="/about">
          <i class="font-14 bg-mint-dark rounded-s"
            ><fa icon="address-card"
          /></i>
          <span>About</span>
          <i><fa icon="angle-right" /></i>
        </router-link>
        <router-link to="/debug" class="border-0">
          <i class="font-14 rounded-s bg-orange-dark"
            ><fa icon="user-secret"
          /></i>
          <span>Debug</span>
          <i><fa icon="angle-right" /></i>
        </router-link>
      </div>
    </div>

    <!-- <div class="mr-3 ml-3 mt-4">
      <span class="text-uppercase font-900 font-11 opacity-30"
        >SOCIAL LINKS</span
      >
      <div class="list-group list-custom-small">
        <a href="#">
          <i class="fab font-14 fa-facebook-f rounded-s bg-facebook"></i>
          <span>Facebook</span>
          <i class="fa fa-link"></i>
        </a>
        <a href="#">
          <i class="fab font-14 fa-twitter rounded-s bg-twitter"></i>
          <span>Twitter</span>
          <i class="fa fa-link"></i>
        </a>
        <a href="#" class="border-0">
          <i class="fab font-14 fa-instagram rounded-s bg-instagram"></i>
          <span>Instagram</span>
          <i class="fa fa-link"></i>
        </a>
      </div>
    </div> -->

    <div class="mr-3 ml-3 mt-4 pt-2 abs-bottom">
      <span class="text-uppercase font-900 font-11 opacity-30"
        >Account Settings</span
      >
      <div class="list-group list-custom-small">
        <router-link to="/subscribe">
          <i class="font-14 rounded-s bg-green2-dark"
            ><fa :icon="['far', 'gift-card']"
          /></i>
          <span>Subscription</span>
          <i><fa in con="angle-right" /></i>
        </router-link>
        <router-link to="/settings">
          <i class="font-14 rounded-s bg-brown1-dark"
            ><fa :icon="['far', 'user-cog']"
          /></i>
          <span>Preferences</span>
          <i><fa icon="angle-right" /></i>
        </router-link>
        <router-link to="/" @click.prevent="logout">
          <i class="font-14 rounded-s bg-brown1-dark"
            ><fa :icon="['far', 'sign-out']"
          /></i>
          <span>Sign Out</span>
          <i><fa icon="angle-right" /></i>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import $ from "jquery";
import { WWUser } from "@/shared/user";
import { ProfileService, ProfileSettings } from "@/services/profileService";
import UserService from "@/services/userService";
import { defineComponent } from '@vue/runtime-core';

const xps = new ProfileService();
const xprefs = new ProfileSettings();

export default defineComponent({
  name: "MainMenu",
  data() {
    return {
      us: UserService,
      registerUrl: "",
      watchInt: 0,
      showSpinner: true,
      spinMsg: "word to your mother",
      ps: xps as ProfileService,
      prefs: xprefs as ProfileSettings
    };
  },
  computed: {
    showDebug():boolean {
      return this.prefs?.general.debugMode || false;
    },
    showLogin():boolean {
      return this.user.signInState == "Unknown";
    },
    user(): WWUser {
      return this.us.currentUser;
    },
  },
  async created() {
    this.prefs = await this.ps.getCurrentSettings();
  },

  async beforeUnmount() {
    $(this.$el).hideMenu();
    console.log("unmount");
  },
  methods: {
    logout() {
      console.log("logout j/k");
    },
  },
});
</script>
<style lang="scss" scoped>
.abs-bottom {
  position: absolute;
  bottom: 0;
  width: 288px; //menu width - margins
}
</style>