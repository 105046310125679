//import 'bootstrap/js/dist/toast';
import { createApp } from 'vue';
import WWApp from './App.vue';
import "./registerServiceWorker";
import router from "./router";
import VueGtag from "vue-gtag-next";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCloudscale, faFacebookF, faTwitter, faGoogle, faApple, faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import { faBookAlt } from '@adroitcode/pro-duotone-svg-icons';
import {
  faBooks, faArrowDown, faStar, faBook, faAngleDown, faEnvelope, faAngleRight, faLink, faTimes, faMoon, faTint, faCircle, faCaretDown,
  faChevronRight, faHome, faCog, faBars, faFile, faBrush, faTrophy, faCheck, faLifeRing, faBolt, faArrowLeft, faAngleLeft, faLightbulb, faLock,
  faUser, faBookOpen, faHeart, faPlus, faChevronDown, faCloud, faChartBar, faCalendarCheck, faPause, faAt, faTv, faCheckCircle, faPlayCircle,
  faEye, faAbacus, faChessQueen, faAddressCard, faUserSecret, faHistory, faEdit, faSync, faExclamation, faDatabase

} from '@adroitcode/pro-solid-svg-icons';
import {
  faStopwatch, faMapMarker, faTally, faCalendar, faBug, faCodeMerge, faMapMarkedAlt, faSignOut, faUserCog, faGiftCard, faFrown, faAd,
  faSlash, faSignalSlash, faTrashAlt, faCookie
} from '@adroitcode/pro-regular-svg-icons';
import { faTypewriter } from '@adroitcode/pro-light-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import { StickyTheme } from './shared/stickyInit';
import { GlobalWatch } from './shared/globalWatch';
import { LogService } from './services/logService';
import { appPlugin } from './shared/appStatePlugin';

library.add(faBooks, faArrowDown, faStar, faBook, faAngleDown, faEnvelope, faAngleRight, faLink, faTint, faTimes, faMoon, faCircle, faCaretDown,
  faChevronRight, faHome, faCog, faBars, faFile, faBrush, faTrophy, faCheck, faLifeRing, faBolt, faCloudscale, faArrowLeft, faAngleLeft);
library.add(
  faLightbulb, faFacebookF, faTwitter, faLock, faUser, faBookAlt, faBookOpen, faHeart, faPlus, faChevronDown, faCloud, faChartBar, faStopwatch,
  faMapMarker, faTally, faCalendar, faPause, faCalendarCheck, faAt, faTypewriter, faTv, faPlayCircle, faCheckCircle, faEye, faAbacus, faChessQueen);
library.add(
  faAddressCard, faBug, faCodeMerge, faGoogle, faApple, faMicrosoft, faMapMarkedAlt, faUserSecret, faSignOut, faUserCog, faGiftCard, faFrown, faAd,
  faSlash, faHistory, faSignalSlash, faEdit, faTrashAlt, faSync, faExclamation, faCookie, faDatabase);

window.ww = {
  initAllCount: 0,
  theme: new StickyTheme()
};
const env = process.env.NODE_ENV || 'development';
const ls = new LogService();
const gw = new GlobalWatch();
gw.init();



function addGtag(app: any) {
  if (env !== 'development') {
    app.use(VueGtag, {
      property: {
        id: "G-VX28LTVGED"
      },
      params: {
        send_page_view: false
      }
    });
  }
}

function addErrorHandler(app: any) {
  app.config.errorHandler = (err: any) => { ls.error(`Vue caught error`, err); };
}

const app = createApp(WWApp)
  .use(router)
  //.use(getAppStore())
  .use(appPlugin)
  .component('fa', FontAwesomeIcon)
  .component('fal', FontAwesomeLayers)
  .component('falt', FontAwesomeLayersText);

addGtag(app);
addErrorHandler(app);
app.mount("#page");