import { SettingsFactory, WWSettings } from "@/shared/config";
import { StickyTheme } from '@/shared/stickyInit';
import { DefaultUser, WWUser } from "@/shared/user";
import { reactive, readonly } from 'vue';
import { LogService } from './logService';
import { get, set } from 'idb-keyval';
const USER_LS_KEY = "current_user";

//this is the one that uses cookies and form download
class UserService {
    private static refreshTimeout = 0;

    public static currentUser: WWUser = reactive(DefaultUser);
    private config: WWSettings;
    private ls = new LogService();
    public requestVerificationToken = reactive({token:''});
    private loginWatch = 0;

    constructor() {
        const settings = new SettingsFactory().getSettings();
        this.config = settings;
        get(USER_LS_KEY).then(uStr => {
            if (uStr?.length) {
                UserService.currentUser = reactive(JSON.parse(uStr)) as WWUser;
            }
        });
        console.log(this.requestVerificationToken);
    }
    public get currentUser() {
        return readonly(UserService.currentUser);
    }
    public async loginPopup() {
        const newwindow = window.open(
            "/account/signin",
            "_blank",
            `toolbar=no,scrollbars=auto, resizable=yes,location=0,left=0,top=0 width=${window.screen.width}, height=${window.screen.height}`
        );
        if (newwindow === null) {
            this.ls.error('could not open login popup window');
            return;
        }
        this.loginWatch = setInterval(async () => {
            try {
                const idx = newwindow?.location?.href?.indexOf("/signedin");
                const errIdx = newwindow?.location?.href?.indexOf("/identity/account/login");
                if (newwindow && idx && idx > 0) {
                    const token = StickyTheme.readCookie('wwToken');
                    const rToken = StickyTheme.readCookie('wwRefreshToken');
                    newwindow.close();
                    clearInterval(this.loginWatch);
                    await this.updateUserFromTokens(token, rToken);
                } else if (newwindow?.closed) {
                    clearInterval(this.loginWatch);
                } else if (newwindow && errIdx && errIdx > 0) {
                    this.ls.warn('error logging in');
                    clearInterval(this.loginWatch);
                    //TODO: toast
                }
            } catch (e) {
                this.ls.error(e);
                clearInterval(this.loginWatch);
            }
        }, 10);
    }
    private updateUserFromTokens(token: string | null, rToken: string | null) {
        if (token?.length) {
            UserService.currentUser.auth = this.getAuthFromToken(token);
            UserService.currentUser.signInState = 'WWLoggedIn';
            StickyTheme.eraseCookie('wwToken');
            if (rToken?.length) {
                UserService.currentUser.auth.refreshToken = rToken;
                StickyTheme.eraseCookie('wwRefreshToken');
            }
            const payload = token.split('.')[1];
            const claimStr = atob(payload);
            const claims = JSON.parse(claimStr);
            UserService.currentUser.avatarUri = claims.picture || UserService.currentUser.avatarUri;
            UserService.currentUser.displayName = claims.given_name || UserService.currentUser.displayName;
            this.saveLoadUser();
        }
    }

    public async initVerificationToken(): Promise<void> {
        const url = new URL('/identity/account/login', this.config.apiPath);
        try {
            let resp = {} as Response;
            if (this.config.fetchWithCredentials) {
                resp = await fetch(url.href, { credentials: 'include' });
            } else {
                resp = await fetch(url.href);
            }

            if (resp.ok) {
                const html = await resp.text();
                let rvToken = html.substring(html.indexOf('__RequestVerificationToken'));
                rvToken = rvToken.substring(rvToken.indexOf('value=') + 7);
                rvToken = rvToken.substring(0, rvToken.indexOf('"'));
                if(!this.requestVerificationToken)
                    throw "fail";
                this.requestVerificationToken.token = rvToken;
            } else {
                this.ls.error(resp.statusText, resp);
            }
        } catch (e) {
            this.ls.error(e);
        }
    }

    public async init() {        
        //do we have stored valid auth token?
        if (UserService.currentUser.auth?.accessToken && UserService.currentUser.auth?.expiration + (60 * 5 * 1000) < Date.now()) {
            UserService.currentUser.signInState = "WWLoggedIn";
            return;
        }

        UserService.currentUser.signInState = "Unknown";
        this.ls.info('no token refresh currently available')
        await this.initVerificationToken();
        //this.setupRefresh();
    }

    // private setupRefresh() {
    //     if (!this.currentUser.auth?.expiration) { return; }
    //     const ttRefresh = Date.now() - this.currentUser.auth.expiration - (60 * 5 * 1000);
    //     clearTimeout(UserService.refreshTimeout);
    //     UserService.refreshTimeout = setTimeout(async () => {
    //         await this.tryAutoSignIn();
    //     }, ttRefresh);
    // }

    private async saveLoadUser() {
        await set(USER_LS_KEY, JSON.stringify(this.currentUser));
        UserService.currentUser = JSON.parse(await get(USER_LS_KEY) as string) as WWUser;
    }

    private getAuthFromToken(token: string) {
        const sects = token.split('.')
        const payload = atob(sects[1]);
        const payObj = JSON.parse(payload);
        return {
            accessToken: token,
            expiration: payObj.exp * 1000,
            refreshToken: null,
            id: payObj.sub
        }
    }
}
export default new UserService();