
export interface UUserService {
    init(): Promise<void>;
    login(): Promise<any>;
    currentUser: WWUser;
}

export type SignInState = "Unknown" | "3PLoggedIn" | "WWLoggedIn";

export interface WWUser {
    avatarUri: string;
    displayName: string;
    signInState: SignInState;
    tagLine?: string;
    auth?: {
        accessToken: string;
        refreshToken: string | null;
        expiration: number;
        id: string;
    };
}

export const DefaultUser: WWUser = {
    avatarUri: "https://www.gravatar.com/avatar/.jpg?d=mp",
    displayName: "Writer Unknown",
    tagLine: "Word slinger",
    signInState: "Unknown"
}