import { App } from 'vue';
import  AppState  from '@/shared/appState';



// plugin
export const appPlugin = {
    install: (app: App, options: any) => {
        //const store = getAppStore();
        app.config.globalProperties.$app = AppState;
        // app.config.globalProperties.$snack = (snack: Snack) => {
        //     store.commit(MutationTypes.PUSH_SNACK, snack);
        // }
        // app.config.globalProperties.$toast = (toast: Toast) => {
        //     store.commit(MutationTypes.PUSH_TOAST, toast);
        // }
    }
};

declare module '@vue/runtime-core' {

    // provide typings for `this.$store`
    //type snackFunc = (s: Snack) => void;
    interface ComponentCustomProperties {
        $app: typeof AppState;
        // $snack: (s: Snack) => void;
        // $toast: (s: Toast) => void;
    }
}