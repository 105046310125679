<template>
  <a @click="onClick">
    <i class="font-14 rounded-s" :class="iconClass"
      ><fa v-if="icon" :icon="icon"
    /></i>
    <span>{{ label }}</span>
    <div class="custom-control scale-switch ios-switch">
      <input
        type="checkbox"
        class="ios-input"
        :class="innerValue ? 'active' : ''"
      />
      <label class="custom-control-label"></label>
    </div>
    <i class="fa fa-chevron-right opacity-30"><fa icon="chevron-right" /></i>
  </a>
  <!-- <div class="d-flex">
    <div class="pt-1" @click="onclick">
      <h5 class="font-600">{{ label }}</h5>
    </div>
    <div class="ml-auto mr-4 pr-2">
      <div class="custom-control ios-switch">
        <input type="checkbox" class="ios-input" id="toggle-id-1" />
        <label class="custom-control-label" for="toggle-id-1"></label>
      </div>
    </div>
  </div> -->
</template>
<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';



export default defineComponent({
  props: {
    initialValue: {
      type: Boolean,
      default: false,
    },
    label: {
      required: true,
      type: String,
      validator: (val: string):boolean => {
        return !!val?.length;
      },
    },
    iconClass: {
      required: false,
      type: String,
      default: "font-14 color-highlight",
    },
    icon: {
      required: false,
      type: Object as PropType<string | string[]>,
      default: null,
    },
  },
  name:"StickyToggle",
  setup(props, { emit }) {
    //const { initialValue } = toRefs(props)
    const innerValue = ref(props.initialValue);
    const onClick = function() {
      innerValue.value = !innerValue.value;
      emit("toggled", innerValue);
    };
    return {
      onClick,
      innerValue,
      ...props
    };
  }
  // data() {
  //   return {
  //     innerValue: false,
  //   };
  // },
  // emits:['toggled'],
  // created() {
  //   const dumb = this.label;
  //   this.innerValue = this.initialValue;
  // },
  // methods: {
  //   onClick() {
  //     this.innerValue = !this.innerValue;
  //     this.$emit("toggled", this.innerValue);
  //   },
  // },  
});
</script>