
import { Options, Vue } from "vue-class-component";
import StickyPage from "@/components/sticky-page.vue";
@Options({
  name: "NotFoundView",
  components: {
    StickyPage,
  },
  props:{
    resource:{
      type: String,
      required: true,
      default: 'unknown'
    }
  }
})
export default class NotFound extends Vue {
  //https://github.com/sdras/vue-sample-svg-icons/blob/master/src/components/IconBase.vue
}
