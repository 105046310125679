import { SettingsFactory } from '@/shared/config';
import { LogService } from './logService';
import UserService from './userService';
import axios from 'axios';
const config = new SettingsFactory().getSettings();
const baseApi = axios.create({ //TODO: logging, cancellation 
    baseURL: "",
    headers: {
        'content-type': 'application/json',
        'ww-version': config.currentVersion
    },
    timeout: 30
});
const queueKey = 'sync-queue-item'
//export type ResultStatus= "Success" | "Queued" | "Error";
export interface ApiResult {
    status: "Success" | "Queued" | "Error";
    details?: any;
}
interface QueueItem {
    pathFragment: string;
    value: string;
    lastUpdated: number;
}

export default class APIService {
    private static runtimeQueue: Array<QueueItem> = [];
    private static loading = false;
    private us = UserService;
    private ls = new LogService();


    constructor() {
        if (!APIService.runtimeQueue.length && !APIService.loading) {
            APIService.loading = true;
            this.loadFromStorage();
            APIService.loading = false;
        }
        window.addEventListener("online", async () => this.toggleNetwork());
        //window.addEventListener("offline", async () => this.toggleNetwork());
    }
    private async toggleNetwork() {
        await this.pushQueue();
    }

    private async pushQueue() {
        if (APIService.runtimeQueue.length) {
            const itm = APIService.runtimeQueue[0];
            await this.sync(itm, 30000);
            await this.pushQueue();
        }
    }

    private loadFromStorage() {
        const q = APIService.runtimeQueue;
        for (let i = 0; ; i++) {
            const key = localStorage.key(i);
            if (!key) {
                break;
            }
            if (!key.startsWith(queueKey)) {
                continue;
            }
            q.push(JSON.parse(localStorage.getItem(key) as string) as QueueItem);
        }
    }

    public async queueItem<T>(pathFragment: string, value: T, lastUpdated: number = Date.now()): Promise<ApiResult> {
        if (!pathFragment?.length || !value) {
            throw "cannot queue null";
        }
        let queued = false;
        try {
            const valIsString = typeof value === "string";
            const key = `${queueKey}_${pathFragment}`;//${Math.floor((Math.random() * 1000000))}`;
            const item = {
                pathFragment: pathFragment,
                value: valIsString ? (value as any as string) : JSON.stringify(value),
                lastUpdated: lastUpdated
            }
            this.addToQueue(item);
            queued = true;
            const result = await this.sync(item);
            const apiResult: ApiResult = { status: "Queued" };
            if (!result) { return apiResult }
            if (result.ok) { apiResult.status = "Success" }
            if (result.status < 500) {
                this.removeFromQueue(item);
                apiResult.status = "Error";
                apiResult.details = result;
            }
            return apiResult;
        } catch (e) {
            this.ls.error(e);
            const apiResult: ApiResult = { status: queued ? "Queued" : "Error", details: e };
            return apiResult;
        }
    }

    private addToQueue(item: QueueItem) {
        let q = APIService.runtimeQueue;

        q = q.filter(eItm => { eItm.pathFragment !== item.pathFragment });
        q.push(item);

        const key = queueKey + "_" + item.pathFragment;
        localStorage.setItem(key, JSON.stringify(item));
    }
    private removeFromQueue(item: QueueItem) {
        let q = APIService.runtimeQueue;

        q = q.filter(eItm => { eItm.pathFragment !== item.pathFragment });

        const key = queueKey + "_" + item.pathFragment;
        localStorage.removeItem(key);
    }

    private async sync(item: QueueItem, timeout = 15000): Promise<Response | null> {

        if (!window.navigator.onLine) {
            return null;
        }
        if (!this.us.currentUser.auth?.accessToken.length) {//TODO: too much depth
            return null;
        }

        const url = new URL(item.pathFragment, config.apiPath).href;
        const params = {
            headers: {
                'authorization': 'Bearer ' + this.us.currentUser.auth.accessToken
            },
            method: 'PUT'
        };
        const result = await this.fetchWithTimeout(url, params, timeout);
        if (result.ok) {
            APIService.runtimeQueue = APIService.runtimeQueue.filter(e => e.pathFragment !== item.pathFragment);
            localStorage.removeItem(queueKey + "_" + item.pathFragment);
        }
        return result;
    }


    private fetchWithTimeout(url: string, params: any, timeout: number): Promise<Response> {
        return new Promise((resolve, reject) => {
            // Set timeout timer
            const timer = setTimeout(
                () => reject(new Error('Request timed out')),
                timeout
            );

            fetch(url).then(
                response => resolve(response),
                err => reject(err)
            ).finally(() => clearTimeout(timer));
        })
    }

}