<template>
  <div id="page">
    <!-- <div class="header header-fixed header-transparent header-logo-center">
      <a href="/" class="header-title color-theme">Word Warden</a>
      <a href="#" data-back-button class="header-icon color-theme header-icon-1"
        ><i class="fas fa-arrow-left"><fa icon="arrow-left" /></i
      ></a>
      <a
        href="#"
        data-toggle-theme
        class="header-icon color-theme header-icon-4"
        ><i class="fas fa-lightbulb"><fa icon="lightbulb" /></i
      ></a>
    </div> -->

    <div class="page-content pb-0 bg-theme">
      <carousel :items-to-show="1">
        <slide>
          <div data-card-height="cover" class="card bg-transparent">
            <div class="card-center">
              <div class="content mt-n5">
                <img
                  class="mb-3 mx-auto"
                  width="320"
                  src="images/undraw/1.svg"
                />
                <h1 class="mt-5 mb-0 font-30">Word Warden</h1>
                <p class="mt-n1 color-highlight font-12">
                  Understand your writing
                </p>
                <p class="boxed-text-xl">
                  Track your progress across unlimited writing projects and get
                  insights into what influences your productivity.
                </p>
              </div>
            </div>
          </div>
        </slide>
        <slide>
          <div data-card-height="cover" class="card bg-transparent">
            <div class="card-center">
              <div class="content mt-n5">
                <img
                  class="mb-3 mx-auto"
                  width="250"
                  src="images/undraw/2.svg"
                />
                <h1 class="mt-5 mb-0 font-30">Built for Writers</h1>
                <p class="mt-n1 color-highlight font-12">
                  We listen to all your Feedback
                </p>
                <p class="boxed-text-xl">
                  Word Warden is built on your suggestions. Check the roadmap
                  and submit your ideas to make it even better.
                </p>
              </div>
            </div>
          </div>
        </slide>
        <slide>
          <div data-card-height="cover" class="card bg-transparent">
            <div class="card-center">
              <div class="content mt-n5">
                <img
                  class="mb-3 mx-auto"
                  width="280"
                  src="images/undraw/3.svg"
                />
                <h1 class="mt-5 mb-0 font-30">Easy to Use</h1>
                <p class="mt-n1 color-highlight font-12">
                  Spend more time writing
                </p>
                <p class="boxed-text-xl">
                  Don't waste time fiddling with complicated apps.
                </p>
              </div>
            </div>
          </div>
        </slide>

        <!-- <div data-card-height="cover" class="card bg-transparent">
          <div class="card-center">
            <div class="content mt-n5">
              <img class="mb-3 mx-auto" width="270" src="images/undraw/5.svg" />
              <h1 class="mt-5 mb-0 font-28">Performance Monster</h1>
              <p class="mt-n1 color-highlight font-12">
                It's Mobile. So it's super, super fast! Really!
              </p>
              <p class="boxed-text-xl">
                Unlike our competitors, we test our products on over 50 live,
                actual devices. Not emulators. We test in real world conditions
                for maximum performance.
              </p>
            </div>
          </div>
        </div>

        <div data-card-height="cover" class="card bg-transparent">
          <div class="card-center">
            <div class="content mt-n5">
              <img class="mb-3 mx-auto" width="270" src="images/undraw/4.svg" />
              <h1 class="mt-5 mb-0 font-28">Fingertip Features</h1>
              <p class="mt-n1 color-highlight font-12">
                Everything is built for your Mobile
              </p>
              <p class="boxed-text-xl">
                Following strict guideliness for your fingertips to enjoy ever
                second of tapping through the features StickyMobile has to
                offer. Pixel perfect built.
              </p>
            </div>
          </div>
        </div>

        <div data-card-height="cover" class="card bg-transparent">
          <div class="card-center">
            <div class="content mt-n5">
              <img class="mb-3 mx-auto" width="285" src="images/undraw/6.svg" />
              <h1 class="mt-5 mb-0 font-28">And much more!</h1>
              <p class="mt-n1 color-highlight font-12">
                All packed together with hundreds of features.
              </p>
              <p class="boxed-text-xl">
                Sticky is not just your average template. We use Bootstrap to
                bring you the best product possible.
              </p>
            </div>
          </div>
        </div> -->

        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
      <div class="cover-button-bottom">
        <button
          @click="getStarted"
          class="btn scale-box btn-m mt-5 btn-center-l rounded-l shadow-xl bg-highlight font-800 text-uppercase"
        >
          Get Started
        </button>
      </div>
      <a href="#" class="cover-next color-gray2-dark"
        >Next<i class="fa fa-angle-right mr-4 ml-3"><fa icon="angle-right" /></i
      ></a>
      <a href="#" class="cover-prev color-gray2-dark"
        ><i class="fa fa-angle-left mr-4 ml-4"><fa icon="angle-left" /></i
        >Previous</a
      >
    </div>
  </div>
</template>

<script lang="ts">
// import $ from "jquery";
// import { UserManager, Log, Profile, OidcClientSettings } from "oidc-client";
import "vue3-carousel/dist/carousel.css";
import { Options, Vue } from "vue-class-component";
import router from "@/router";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
// @ is an alias to /src
@Options({
  name: "Walkthrough",
  components: { Carousel, Slide, Pagination, Navigation },
})
export default class WalkthroughView extends Vue {
  public async getStarted() {
    window.ww.theme.createCookie("hidewalk", 1, 1);
    router.push("/");
    //window.location.href ="https://auth.wordwarden.app/account/register"
  }

  public async mounted() {
    window.ww.theme.initAll();
  }
}
</script>