<template>
  <sticky-page>
    <template v-slot:default>
      <div class="row text-center mb-0">
        <div class="col-12">
          <div class="card card-style">
            <router-link to="/profile" class="d-flex m-3">
              <div>
                <img
                  :src="user.avatarUri || '/images/pictures/faces/1s.png'"
                  class="mr-3 rounded-circle shadow-l"
                  width="60"
                  height="60"
                />
              </div>
              <div>
                <h3 class="mt-2 mb-0 font-700">{{ user.displayName }}</h3>
                <p class="font-12 mt-n1 mb-0 pb-0">{{ user.tagLine }}</p>
              </div>
              <div class="ml-auto mt-3 pt-1">
                <i class="fa fa-chevron-right color-theme opacity-30">
                  <fa icon="chevron-right" />
                </i>
              </div>
            </router-link>
            <template v-if="showLogin">
              <div class="divider mb-1"></div>
              <div
                class="font-11 color-theme opacity-60 pb-1 text-right"
                v-if="!user.isLoggedIn"
              >
                <a @click.prevent="loginClick" href="#" class="mr-3 color-theme"
                  >Login</a
                >
              </div>
            </template>
          </div>
        </div>
        <!--HERE-->
        <router-link to="/projects" class="col-6 pr-0">
          <div class="card card-style mb-3 mr-2">
            <i class="fa fa-home color-green1-dark fa-3x mt-5">
              <fa icon="books" />
            </i>
            <h1 class="pt-4">Projects</h1>
            <p class="font-11 opacity-50 mt-n1">What are you writing</p>
            <p class="mb-0 pb-0 font-10 opacity-40">Tap to View</p>
          </div>
        </router-link>
        <router-link to="/settings" class="col-6 pl-0">
          <div class="card card-style mb-3 ml-2">
            <i class="fa fa-cog color-blue2-dark fa-3x mt-5">
              <fa icon="cog" />
            </i>
            <h1 class="pt-4">Settings</h1>
            <p class="font-11 opacity-50 mt-n1">Control your experience</p>
            <p class="mb-0 pb-0 font-10 opacity-40">Tap to View</p>
          </div>
        </router-link>
        <!-- <div
            class="col-12 mb-3 ad-300x50 bg-highlight ad-300x50-fixed"
            v-if="showHomeAd"
          >
            <h6
              class="color-white text-center pt-3 font-15 font-700 text-uppercase"
            >
              Mobile banner - 300x50
            </h6>
          </div> -->
        <router-link
          to="/contact"
          class="col-6 pr-0"
          :class="showLogin ? 'opacity-40' : ''"
          :title="showLogin ? 'Login to contact us' : 'Contact Us'"
        >
          <div class="card card-style mb-3 mr-2">
            <i class="fa fa-envelope color-red2-dark fa-3x mt-5">
              <fa icon="envelope" />
            </i>
            <h1 class="pt-4">Contact</h1>
            <p class="font-11 opacity-50 mt-n1">Get in touch</p>
            <p class="mb-0 pb-0 font-10 opacity-40">Tap to View</p>
          </div>
        </router-link>
        <router-link to="/pomodoro" class="col-6 pl-0 opacity-40">
          <div class="card card-style mb-3 ml-2">
            <i class="fa fa-bars color-brown1-dark fa-3x mt-5">
              <fa :icon="['far', 'stopwatch']" />
            </i>
            <h1 class="pt-4">Pomodoro</h1>
            <p class="font-11 opacity-50 mt-n1">Coming Soon!</p>
            <p class="mb-0 pb-0 font-10 opacity-40">Tap to View</p>
          </div>
        </router-link>
        <router-link to="/about" class="col-6 pr-0">
          <div class="card card-style mb-3 mr-2">
            <i class="fa fa-envelope color-mint-dark fa-3x mt-5">
              <fa icon="address-card" />
            </i>
            <h1 class="pt-4">About</h1>
            <p class="font-11 opacity-50 mt-n1">App details</p>
            <p class="mb-0 pb-0 font-10 opacity-40">Tap to View</p>
          </div>
        </router-link>
        <router-link to="/debug" class="col-6 pl-0" v-show="showDebug">
          <div class="card card-style mb-3 ml-2">
            <i class="fa fa-envelope color-orange-dark fa-3x mt-5">
              <fa icon="user-secret" />
            </i>
            <h1 class="pt-4">Debug</h1>
            <p class="font-11 opacity-50 mt-n1">Runtime Info</p>
            <p class="mb-0 pb-0 font-10 opacity-40">Tap to View</p>
          </div>
        </router-link>
        <!-- <a href="/register" target="_blank" class="col-6 pr-0"> π </a> -->
      </div>
    </template>
  </sticky-page>
</template>

<script lang="ts">
import StickyPage from "@/components/sticky-page.vue";
import { Options, Vue } from "vue-class-component";
import { WWUser } from "@/shared/user";
import { ProfileService, ProfileSettings } from "@/services/profileService";
import UserService from "@/services/userService";

@Options({
  name: "Home",
  components: {
    StickyPage,
  },
})
export default class HomeView extends Vue {
  private us = UserService;
  private ps = new ProfileService();
  private prefs: ProfileSettings | null = null;

  private get showDebug() {
    return this.prefs?.general.debugMode || false;
  }
  private get showLogin() {
    return this.user.signInState == "Unknown";
  }
  public get user(): WWUser {
    return this.us.currentUser;
  }
  public get isWebLaunched() {
    return window.ww.displayMode !== "standalone-ios";
  }
  public get showHomeAd() {
    return false; //TODO: this
  }
  public async created() {
    this.prefs = await this.ps.getCurrentSettings();
  }
  public async loginClick() {
    await this.us.loginPopup();
  }
}
</script>