import { ProfileService, ProfileSettings } from '@/services/profileService';
import $ from "jquery";

export class StickyTheme {
    // generating Dynamic Styles to decrease CSS size and execute faster loading times. 
    public colorsArray = [
        // colors must be in HEX format.
        // use the color scheme as bellow  this will automatically generate CSS for
        // bg-colorName-dark bg-colorName-light color-colorName-dark color-colorName-light
        // ["colorName","light_hex","dark_hex","darker_hex_for_gradient"], 
        ["none", "", "", ""],
        ["plum", "#6772A4", "#6772A4", "#3D3949"],
        ["violet", "#673c58", "#673c58", "#492D3D"],
        ["magenta3", "#413a65", "#413a65", "#2b2741"],
        ["red3", "#c62f50", "#6F1025", "#6F1025"],
        ["green3", "#6eb148", "#2d7335", "#2d7335"],
        ["pumpkin", "#E96A57", "#C15140", "#C15140"],
        ["dark3", "#535468", "#535468", "#343341"],
        ["red1", "#D8334A", "#BF263C", "#9d0f23"],
        ["red2", "#ED5565", "#DA4453", "#a71222"],
        ["orange", "#FC6E51", "#E9573F", "#ce3319"],
        ["yellow1", "#FFCE54", "#F6BB42", "#e6a00f"],
        ["yellow2", "#E8CE4D", "#E0C341", "#dbb50c"],
        ["yellow3", "#CCA64F", "#996A22", "#996A22"],
        ["green1", "#A0D468", "#8CC152", "#5ba30b"],
        ["green2", "#2ECC71", "#2ABA66", "#0da24b"],
        ["mint", "#48CFAD", "#37BC9B", "#0fa781"],
        ["teal", "#A0CECB", "#7DB1B1", "#158383"],
        ["aqua", "#4FC1E9", "#3BAFDA", "#0a8ab9"],
        ["sky", "#188FB6", "#0F5F79", "#0F5F79"],
        ["blue1", "#4FC1E9", "#3BAFDA", "#0b769d"],
        ["blue2", "#5D9CEC", "#4A89DC", "#1a64c6"],
        ["magenta1", "#AC92EC", "#967ADC", "#704dc9"],
        ["magenta2", "#8067B7", "#6A50A7", "#4e3190"],
        ["pink1", "#EC87C0", "#D770AD", "#c73c8e"],
        ["pink2", "#fa6a8e", "#fb3365", "#d30e3f"],
        ["brown1", "#BAA286", "#AA8E69", "#896b43"],
        ["brown2", "#8E8271", "#7B7163", "#584934"],
        ["gray1", "#F5F7FA", "#E6E9ED", "#c2c5c9"],
        ["gray2", "#CCD1D9", "#AAB2BD", "#88919d"],
        ["dark1", "#656D78", "#434A54", "#242b34"],
        ["dark2", "#3C3B3D", "#323133", "#1c191f"]
    ];
    public socialColorArray = [
        ["facebook", "#3b5998"],
        ["linkedin", "#0077B5"],
        ["twitter", "#4099ff"],
        ["google", "#d34836"],
        ["microsoft", "#2f2f2f"],
        ["whatsapp", "#34AF23"],
        ["pinterest", "#C92228"],
        ["sms", "#27ae60"],
        ["mail", "#3498db"],
        ["dribbble", "#EA4C89"],
        ["phone", "#27ae60"],
        ["skype", "#12A5F4"],
        ["instagram", "#e1306c"],
        ["apple", "#1D1D1F"]
    ];

    private header: JQuery<HTMLElement>;
    private menuFooter: JQuery<HTMLElement>;
    private static instance: StickyTheme;
    private fullInit = false;
    public constructor() {
        StickyTheme.instance = this;
        this.header = $(".header");
        this.menuFooter = $("#footer-bar");
        // one time init stuff here (not every view change)

        // back Button Scroll Stop
        if ("scrollRestoration" in history) { history.scrollRestoration = "manual"; }

        /*Menu Extender Function*/
        $.fn.showMenu = function () { $(this).addClass("menu-active"); $("#footer-bar").addClass("footer-menu-hidden"); setTimeout(function () { $(".menu-hider").addClass("menu-active"); }, 250); };
        $.fn.hideMenu = function () { $(this).removeClass("menu-active"); $("#footer-bar").removeClass("footer-menu-hidden"); $(".menu-hider").removeClass("menu-active"); };
        $(window).resize(() => {
            this.card_extender();
        });
    }
    //only call this in views that aren't using sticky page!
    public initAll(ele: HTMLElement) {
        //$(document).add('*').off();
        window.ww.initAllCount++;

        //Disable Page Jump on Empty Links.
        if (!this.fullInit) {
            $(document.body).on("click", "a", function () { const attrs = $(this).attr("href"); if (attrs === "#") { return false; } });
        }
        
        this.hidePreloader(ele);
        this.initMenus(ele);
        this.initDarkMode(ele);
        //this.initOwlCarousel(ele);
        //this.initSwitches(ele);
        this.initTabs(ele);
        this.initColor(ele);
        this.initToastSnackNote(ele);
        this.card_extender(ele);
        this.initInputs(ele);
        this.initEnvironment(ele);
        this.initShareMenu(ele);//not needed and use native
        this.initCardEffects(ele);
        //this.initPreloadImg(ele);
        this.initCollapse(ele);
        this.initBack2TopCopyright(ele);
        this.initScrollWatch(ele);
        this.fullInit = true;
    }
    public initCollapse(ele: HTMLElement) {
        //Cancel Collapse Jump on Click
        if (this.fullInit) { return; }

        $(document.body).on('click', "[data-toggle=\"collapse\"]", function (e) {
            e.preventDefault();
        });

    }
    // public initPreloadImg(ele: HTMLElement) {
    //     //Preload Image
    //     const preloadImages = $(".preload-img", ele);
    //     $(function () { preloadImages.lazyload({ threshold: 500 }); });
    // }

    public initCardEffects(ele: HTMLElement) {
        //Card Hovers
        $('.card-scale', ele).unbind().bind('mouseenter mouseleave touchstart touchend', function () { $(this).find('img').toggleClass('card-scale-image'); });
        $('.card-grayscale', ele).unbind().bind('mouseenter mouseleave touchstart touchend', function () { $(this).find('img').toggleClass('card-grayscale-image'); });
        $('.card-rotate', ele).unbind().bind('mouseenter mouseleave touchstart touchend', function () { $(this).find('img').toggleClass('card-rotate-image'); });
        $('.card-blur', ele).unbind().bind('mouseenter mouseleave touchstart touchend', function () { $(this).find('img').toggleClass('card-blur-image'); });
        $('.card-hide', ele).unbind().bind('mouseenter mouseleave touchstart touchend', function () { $(this).find('.card-center, .card-bottom, .card-top, .card-overlay').toggleClass('card-hide-image'); });

    }

    public hidePreloader(ele: HTMLElement): void {
        $(".menu", ele).css("display", "block");// taken from earlier on load function
        $("#preloader", ele).addClass("preloader-hide");
    }

    public static createCookie(name: string, value: any, n: number): string {
        let r = "";
        if (n) {
            const o = new Date();
            o.setTime(o.getTime() + 48 * n * 60 * 3600 * 1e3);
            r = "; expires=" + o.toUTCString();
        }
        return document.cookie = name + "=" + value + r + "; path=/";
    }
    public static readCookie(e: string) {
        let r = "";
        for (let t = e + "=", n = document.cookie.split(";"), o = 0; o < n.length; o++) {
            for (r = n[o]; " " == r.charAt(0);)
                r = r.substring(1, r.length);
            if (0 == r.indexOf(t))
                return r.substring(t.length, r.length)
        }
        return null;
    }
    public static eraseCookie(e: string) { StickyTheme.createCookie(e, "", -1); }

    public initMenus(ele?: HTMLElement) {
        // menu Required Variables
        const menu = $(".menu"),
            body = $("body"),
            menuFixed = $(".nav-fixed"),
            menuHider = $("body").find(".menu-hider"),
            menuClose = $(".close-menu"),
            pageAll = $("#page"),
            pageContent = $(".page-content"),
            headerAndContent = $(".header, .page-content, #footer-bar")

        this.header = $(".header");
        this.menuFooter = $("#footer-bar");
        // menu System
        menu.each(function () {
            const menuHeight = $(this).data("menu-height");
            const menuWidth = $(this).data("menu-width");
            const menuActive = $(this).data("menu-active");
            if ($(this).hasClass("menu-box-right")) { $(this).css("width", menuWidth); }
            if ($(this).hasClass("menu-box-left")) { $(this).css("width", menuWidth); }
            if ($(this).hasClass("menu-box-bottom")) { $(this).css("height", menuHeight); }
            if ($(this).hasClass("menu-box-top")) { $(this).css("height", menuHeight); }
            if ($(this).hasClass("menu-box-modal")) { $(this).css({ "height": menuHeight, "width": menuWidth }); }
        });

        const autoActivateMenu = $("[data-auto-activate]");
        if (autoActivateMenu.length) {
            autoActivateMenu.addClass("menu-active");
            menuHider.addClass("menu-active");
        }

        // allows clicking even if menu is loaded externally.
        $("body").removeClass("modal-open");

        if (this.fullInit) { return }
        // menu Deploy Click
        $(document.body).on("click", "a[data-menu],button[data-menu]", function () {
            const menu = $(".menu"),
                menuHider = $("body").find(".menu-hider"),
                headerAndContent = $(".header, .page-content, #footer-bar");

            menu.removeClass("menu-active");
            menuHider.addClass("menu-active");

            const menuData = $(this).data("menu");
            const menuID = $("#" + menuData);
            const menuEffect = $("#" + menuData).data("menu-effect");
            const menuWidth = menuID.data("menu-width");
            const menuHeight = menuID.data("menu-height");
            $("body").addClass("modal-open");
            if (menuID.hasClass("menu-header-clear")) { menuHider.addClass("menu-active-clear"); }


            if (menuID.hasClass("menu-box-bottom")) { $("#footer-bar").addClass("footer-menu-hidden"); }
            if (menuEffect === "menu-parallax") {
                if (menuID.hasClass("menu-box-bottom")) { headerAndContent.css("transform", "translateY(" + (menuHeight / 5) * (-1) + "px)"); }
                if (menuID.hasClass("menu-box-top")) { headerAndContent.css("transform", "translateY(" + (menuHeight / 5) + "px)"); }
                if (menuID.hasClass("menu-box-left")) { headerAndContent.css("transform", "translateX(" + (menuWidth / 5) + "px)"); }
                if (menuID.hasClass("menu-box-right")) { headerAndContent.css("transform", "translateX(" + (menuWidth / 5) * (-1) + "px)"); }
            }
            if (menuEffect === "menu-push") {
                if (menuID.hasClass("menu-box-bottom")) { headerAndContent.css("transform", "translateY(" + (menuHeight) * (-1) + "px)"); }
                if (menuID.hasClass("menu-box-top")) { headerAndContent.css("transform", "translateY(" + (menuHeight) + "px)"); }
                if (menuID.hasClass("menu-box-left")) { headerAndContent.css("transform", "translateX(" + (menuWidth) + "px)"); }
                if (menuID.hasClass("menu-box-right")) { headerAndContent.css("transform", "translateX(" + (menuWidth) * (-1) + "px)"); }
            }
            if (menuEffect === "menu-push-full") {
                if (menuID.hasClass("menu-box-left")) { headerAndContent.css("transform", "translateX(100%)"); }
                if (menuID.hasClass("menu-box-right")) { headerAndContent.css("transform", "translateX(-100%)"); }
            }
            menuID.toggleClass("menu-active");
        });

        //Adding Background for Gradient
        if (!$('.menu-hider').length) { $('#page').append('<div class="menu-hider"><div>'); }

        $(document.body).on("click", ".menu-hider, .close-menu, .menu-close", function () {
            const menu = $(".menu"),
                menuHider = $("body").find(".menu-hider"),
                headerAndContent = $(".header, .page-content, #footer-bar");

            menu.removeClass("menu-active");
            menuHider.removeClass("menu-active menu-active-clear");
            headerAndContent.css("transform", "translate(0,0)");
            menuHider.css("transform", "translate(0,0)");
            $("#footer-bar").removeClass("footer-menu-hidden");
            $("body").removeClass("modal-open");
            return false;
        });
    }

    public initDarkMode(ele: HTMLElement) {
        if (this.fullInit) { return; }
        //Disabling & Enabling Dark Transitions in Dark Mode to Speed up Performance.
        function allowTransitions() { $("body").find("#transitions-remove").remove(); }
        function removeTransitions() { $("body").append("<style id=\"transitions-remove\">.btn, .header, #footer-bar, .menu-box, .menu-active{transition:all 0ms ease!important;}</style>"); setTimeout(function () { allowTransitions(); }, 10); }

        //Dark Mode
        const darkSwitch = $("[data-toggle-theme-switch], [data-toggle-theme], [data-toggle-theme-switch] input, [data-toggle-theme] input");
        $(document.body).on('click', "[data-toggle-theme], [data-toggle-theme-switch]", () => {
            removeTransitions();
            $("body").toggleClass("theme-light theme-dark");
            setTimeout(async () => {
                if ($("body").hasClass("detect-theme")) { $("body").removeClass("detect-theme"); }
                if ($("body").hasClass("theme-light")) {
                    StickyTheme.eraseCookie("sticky_dark_mode");
                    darkSwitch.prop("checked", false);
                    StickyTheme.createCookie("sticky_light_mode", true, 1);
                }
                if ($("body").hasClass("theme-dark")) {
                    StickyTheme.eraseCookie("sticky_light_mode");
                    darkSwitch.prop("checked", true);
                    StickyTheme.createCookie("sticky_dark_mode", true, 1);
                }
            }, 150);
        })
        if (StickyTheme.readCookie("sticky_dark_mode")) { darkSwitch.prop("checked", true); $("body").removeClass("theme-light").addClass("theme-dark"); }
        if (StickyTheme.readCookie("sticky_light_mode")) { darkSwitch.prop("checked", false); $("body").removeClass("theme-dark").addClass("theme-light"); }


        //Auto Dark Mode
        function activateDarkMode() { $("body").removeClass("theme-light").addClass("theme-dark"); $("#dark-mode-detected").removeClass("disabled"); StickyTheme.eraseCookie("sticky_light_mode"); StickyTheme.createCookie("sticky_dark_mode", true, 1); }
        function activateLightMode() { $("body").removeClass("theme-dark").addClass("theme-light"); $("#dark-mode-detected").removeClass("disabled"); StickyTheme.eraseCookie("sticky_dark_mode"); StickyTheme.createCookie("sticky_light_mode", true, 1); }
        function activateNoPreference() { $("#manual-mode-detected").removeClass("disabled"); }

        function setColorScheme() {
            const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches
            const isLightMode = window.matchMedia("(prefers-color-scheme: light)").matches
            const isNoPreference = window.matchMedia("(prefers-color-scheme: no-preference)").matches
            window.matchMedia("(prefers-color-scheme: dark)").addListener(e => e.matches && activateDarkMode())
            window.matchMedia("(prefers-color-scheme: light)").addListener(e => e.matches && activateLightMode())
            window.matchMedia("(prefers-color-scheme: no-preference)").addListener(e => e.matches && activateNoPreference())
            if (isDarkMode) activateDarkMode();
            if (isLightMode) activateLightMode();
        }
        if ($("body").hasClass("detect-theme")) { setColorScheme(); }
        $(".detect-dark-mode", ele).on("click", function () { $("body").addClass("detect-theme"); setColorScheme(); return false; });
        $(".disable-auto-dark-mode", ele).on("click", function () { $("body").removeClass("detect-theme"); $(this).remove(); return false; });

        //Footer Menu Active Elements
        if ($(".footer-bar-2, .footer-bar-4, .footer-bar-5", ele).length) {
            if (!$(".footer-bar-2 strong, .footer-bar-4 strong, .footer-bar-5 strong", ele).length) {
                $(".footer-bar-2 .active-nav, .footer-bar-4 .active-nav, .footer-bar-5 .active-nav", ele).append("<strong></strong>")
            }
        }
    }

    public initTabs(ele?: HTMLElement) {
        //Tabs//
        const tab = $(".tab-controls");

        function activate_tabs() {
            //const tabTrigger = $(".tab-controls a");
            tab.each(function () {
                const tabItems = $(this).parent().find(".tab-controls").data("tab-items");
                const tabWidth = $(this).width();
                const tabActive = $(this).find("a[data-tab-active]");
                const tabID = $("#" + tabActive.data("tab"));
                const tabBg = $(this).data("tab-active");
                $(this).find("a[data-tab]").css("width", (100 / tabItems) + "%");
                tabActive.addClass(tabBg);
                tabActive.addClass("color-white");
                tabID.slideDown(0);
            });
        }
        if (!this.fullInit) {
            $(document.body).on("click", ".tab-controls a", function () {
                const tabData = $(this).data("tab");
                const tabID = $("#" + tabData);
                const tabContent = $(this).parent().parent().find(".tab-content");
                //const tabContent = $(this).parent().parent().parent().find(".tab-content");
                const tabOrder = $(this).data("tab-order");
                const tabBg = $(this).parent().parent().find(".tab-controls").data("tab-active");
                $(this).parent().find($(".tab-controls a")).removeClass(tabBg).removeClass("color-white");
                $(this).addClass(tabBg).addClass("color-white");
                $(this).parent().find("a").removeClass("no-click");
                $(this).addClass("no-click");
                tabContent.slideUp(250);
                tabID.slideDown(250);
            });
        }
        if (tab.length) { activate_tabs() }
    }

    // public initOwlCarousel(ele?: HTMLElement) {
    //     //Owl Carousel Sliders
    //     setTimeout(function () {
    //         $(".user-slider").owlCarousel({ loop: false, margin: 20, nav: false, lazyLoad: true, items: 1, autoplay: false, dots: false, autoplayTimeout: 8000 });
    //         $(".single-slider").owlCarousel({ navText: ["<fa icon='angle-left'></fa>", "<fa icon='angle-right'></fa>"], loop: true, margin: 20, nav: true, lazyLoad: true, items: 1, autoplay: true, autoplayTimeout: 8000 });
    //         $(".cover-slider").owlCarousel({ loop: true, margin: 0, nav: false, lazyLoad: true, items: 1, autoplay: true, autoplayTimeout: 8000 });
    //         $(".double-slider").owlCarousel({ loop: true, margin: 20, nav: false, lazyLoad: false, items: 2, autoplay: true, autoplayTimeout: 8000 });
    //         $(".task-slider").owlCarousel({ loop: true, margin: 20, nav: false, stagePadding: 50, lazyLoad: true, items: 2, autoplay: false, autoplayTimeout: 8000 });

    //     }, 10);
    //     setTimeout(function () {
    //         $(".owl-prev, .owl-next").addClass("bg-highlight");
    //     })
    //     if (this.fullInit) { return }
    //     $(document.body).on("click", ".next-slide, .next-slide-arrow, .next-slide-text, .cover-next", function () { $(this).parent().find(".owl-carousel").trigger("next.owl.carousel"); });
    //     $(document.body).on("click", ".prev-slide, .prev-slide-arrow, .prev-slide-text, .cover-prev", function () { $(this).parent().find(".owl-carousel").trigger("prev.owl.carousel"); });
    //     $(document.body).on("click", ".next-slide-user", function () { $(this).closest(".owl-carousel").trigger("next.owl.carousel"); });
    //     $(document.body).on("click", ".prev-slide-user", function () { $(this).closest(".owl-carousel").trigger("prev.owl.carousel"); });
    // }

    public initSwitches(ele?: HTMLElement) {
        if (this.fullInit) { return }
        //Switches
        $(document.body).on("click", ".ios-input, .android-input, .classic-input", function () {
            const id = $(this).attr("id");
            const data = $("[data-switch=" + id + "]")
            if (data.length) { data.stop().animate({ height: "toggle" }, 250); }
        });
        $(document.body).on("click", "[data-activate]", function () {
            const activateCheck = $(this).data("activate");
            $("#" + activateCheck).trigger("click");
        });

        $(document.body).on("click", "[data-trigger-switch]", function () {
            const thisID = $(this).data("trigger-switch");
            if ($("#" + thisID).prop("checked")) {
                $("#" + thisID).prop("checked", false);
                $("#" + thisID).removeAttr("checked");
            } else {
                $("#" + thisID).prop("checked", true);
                $("#" + thisID).attr("checked", "checked");
            }
        })
    }

    public initColor(ele?: HTMLElement) {

        if (this.fullInit) { return }

        //Style Generator
        const generatedStyles = $(".generated-styles");
        const generatedHighlight = $(".generated-highlight");

        //HEX to RGBA Converter
        function HEXtoRGBA(hex: string) {
            let c;
            if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
                c = hex.substring(1).split("");
                if (c.length == 3) { c = [c[0], c[0], c[1], c[1], c[2], c[2]]; }
                c = "0x" + c.join("");
                const nc = c as any as number;
                return "rgba(" + [(nc >> 16) & 255, (nc >> 8) & 255, nc & 255].join(",") + ",0.3)";
            }
        }

        function highlight_colors() {

            let bodyColor = StickyTheme.readCookie("sticky-color-scheme")
            if (bodyColor == undefined) { bodyColor = $("body").data("highlight"); }

            let bodyBackground = StickyTheme.readCookie("sticky-bg-scheme")
            if (bodyBackground == undefined) { bodyBackground = $("body").data("background"); }

            const data = StickyTheme.instance.colorsArray.map(colorsArray => colorsArray[0]);
            if (data.indexOf(bodyColor as string) > -1) {
                const highlightLocated = data.indexOf(bodyColor as string)
                const backgroundLocated = data.indexOf(bodyBackground as string)
                const highlightColorCode = StickyTheme.instance.colorsArray[highlightLocated][2]
                const backgroundColorCode = StickyTheme.instance.colorsArray[backgroundLocated][3] + ", " + StickyTheme.instance.colorsArray[backgroundLocated][1]
                const highlightColor = ".color-highlight{color:" + highlightColorCode + "!important}"
                const highlightBg = ".bg-highlight, .page-item.active a{background-color:" + highlightColorCode + "!important}"
                const highlightNav = ".footer-bar-1 .router-link-active *, .footer-bar-3 .active-nav i{color:" + highlightColorCode + "!important} .footer-bar-2 strong, .footer-bar-4 strong, .footer-bar-5 strong{background-color:" + highlightColorCode + "!important; color:#FFF;}"
                const highlightBorder = ".border-highlight{border-color:" + highlightColorCode + "!important}"
                const highlightHeaderTabs = ".header-tab-active{border-color:" + highlightColorCode + "!important}"
                const bodyBG = "#page{background: linear-gradient(0deg, " + backgroundColorCode + ")!important;} .bg-page{background: linear-gradient(0deg, " + backgroundColorCode + ")!important }"
                if (!generatedHighlight.length) {
                    $("body").append("<style class=\"generated-highlight\"></style>")
                    $("body").append("<style class=\"generated-background\"></style>")
                    $(".generated-highlight").append(highlightColor, highlightBg, highlightNav, highlightBorder, highlightHeaderTabs);
                    $(".generated-background").append(bodyBG);
                }
            }
        }
        highlight_colors();

        //Change Highlight
        $(document.body).on("click", "[data-change-highlight]", function (changeColor) {
            const highlightNew = $(this).data("change-highlight");
            $("body").attr("data-highlight", highlightNew);
            $(".generated-highlight").remove();
            StickyTheme.createCookie("sticky-color-scheme", highlightNew, 1)
            const data = StickyTheme.instance.colorsArray.map(colorsArray => StickyTheme.instance.colorsArray[0]);
            if (data.indexOf(highlightNew) > -1) {
                const highlightLocated = data.indexOf(highlightNew)
                let highlightColorCode = "";
                if ($(this).data("color-light") !== undefined) {
                    highlightColorCode = StickyTheme.instance.colorsArray[highlightLocated][1]
                } else {
                    highlightColorCode = StickyTheme.instance.colorsArray[highlightLocated][2]
                }
                const highlightColor = ".color-highlight{color:" + highlightColorCode + "!important}"
                const highlightBg = ".bg-highlight{background-color:" + highlightColorCode + "!important}"
                const highlightNav = ".router-link-active *{color:" + highlightColorCode + "!important} .active-nav *{color:" + highlightColorCode + "!important} .active-nav2 strong{background-color:" + highlightColorCode + "!important}  .active-nav3 strong{background-color:" + highlightColorCode + "!important} .active-nav4 strong{border-color:" + highlightColorCode + "!important}"
                const highlightBorder = ".border-highlight{border-color:" + highlightColorCode + "!important}"
                $("body").append("<style class=\"generated-highlight\"></style>")
                $(".generated-highlight").append(highlightColor, highlightBg, highlightNav, highlightBorder);
            }
        });

        //Change Background
        $(document.body).on("click", "[data-change-background]", function (changeColor) {
            const backgroundNew = $(this).data("change-background");
            StickyTheme.createCookie("sticky-bg-scheme", backgroundNew, 1)
            $(".generated-background").remove();
            const data = StickyTheme.instance.colorsArray.map(colorsArray => colorsArray[0]);
            const backgroundLocated = data.indexOf(backgroundNew)
            const backgroundColorCode = StickyTheme.instance.colorsArray[backgroundLocated][3] + ", " + StickyTheme.instance.colorsArray[backgroundLocated][1]
            const bodyBG = "#page{background: linear-gradient(0deg, " + backgroundColorCode + ")!important;} .bg-page{background: linear-gradient(0deg, " + backgroundColorCode + ")!important}"
            $("body").append("<style class=\"generated-background\"></style>")
            $(".generated-background").append(bodyBG);
        });

        if (!generatedStyles.length) {
            $("body").append("<style class=\"generated-styles\"></style>");
            $(".generated-styles").append("/*Generated using JS for lower CSS file Size, Easier Editing & Faster Loading*/");
            StickyTheme.instance.colorsArray.forEach(function (colorValue) { $(".generated-styles").append(".bg-" + colorValue[0] + "-light{ background-color: " + colorValue[1] + "!important; color:#FFFFFF!important;} .bg-" + colorValue[0] + "-light i, .bg-" + colorValue[0] + "-dark i{color:#FFFFFF;} .bg-" + colorValue[0] + "-dark{  background-color: " + colorValue[2] + "!important; color:#FFFFFF!important;} .border-" + colorValue[0] + "-light{ border-color:" + colorValue[1] + "!important;} .border-" + colorValue[0] + "-dark{  border-color:" + colorValue[2] + "!important;} .color-" + colorValue[0] + "-light{ color: " + colorValue[1] + "!important;} .color-" + colorValue[0] + "-dark{  color: " + colorValue[2] + "!important;}"); });
            StickyTheme.instance.colorsArray.forEach(function (colorFadeValue) { $(".generated-styles").append(".bg-fade-" + colorFadeValue[0] + "-light{ background-color: " + HEXtoRGBA(colorFadeValue[1]) + "!important; color:#FFFFFF;} .bg-fade-" + colorFadeValue[0] + "-light i, .bg-" + colorFadeValue[0] + "-dark i{color:#FFFFFF;} .bg-fade-" + colorFadeValue[0] + "-dark{  background-color: " + HEXtoRGBA(colorFadeValue[2]) + "!important; color:#FFFFFF;} .border-fade-" + colorFadeValue[0] + "-light{ border-color:" + HEXtoRGBA(colorFadeValue[1]) + "!important;} .border-fade-" + colorFadeValue[0] + "-dark{  border-color:" + HEXtoRGBA(colorFadeValue[2]) + "!important;} .color-fade-" + colorFadeValue[0] + "-light{ color: " + HEXtoRGBA(colorFadeValue[1]) + "!important;} .color-fade-" + colorFadeValue[0] + "-dark{  color: " + HEXtoRGBA(colorFadeValue[2]) + "!important;}"); });
            StickyTheme.instance.colorsArray.forEach(function (gradientValue) { $(".generated-styles").append(".bg-gradient-" + gradientValue[0] + "{background-image: linear-gradient(to bottom, " + gradientValue[1] + " 0, " + gradientValue[2] + " 100%)}") });
            StickyTheme.instance.socialColorArray.forEach(function (socialColorValue) { $(".generated-styles").append(".bg-" + socialColorValue[0] + "{background-color:" + socialColorValue[1] + "!important; color:#FFFFFF;} .color-" + socialColorValue[0] + "{color:" + socialColorValue[1] + "!important;}") });
            StickyTheme.instance.colorsArray.forEach(function (gradientBodyValue) { $(".generated-styles").append(".body-" + gradientBodyValue[0] + "{background-image: linear-gradient(to bottom, " + gradientBodyValue[1] + " 0, " + gradientBodyValue[3] + " 100%)}") });
        }
    }


    public initToastSnackNote(ele?: HTMLElement) {
        // //Tooltips
        // $(function () {
        //     $("[data-toggle=\"tooltip\"]").tooltip()
        // })

        //Toast, Snackbars and Notifications
        if (this.fullInit) { return; }
        $(document.body).on("click", "[data-toast]", function () {
            $(".toast, .snackbar-toast, .notification").toast("hide");
            $("#" + $(this).data("toast")).toast("show");
            return false;
        });
        $(document.body).on("click", "[data-dismiss]", function () {
            const thisData = $(this).data("dismiss");
            $("#" + thisData).toast("hide");
        });
    }
    //Extending Card Features
    private card_extender(ele?: HTMLElement) {
        const header = $('.header');
        let windowHeight = 0;
        /*Set Page Content to Min 100vh*/
        if ($('.is-on-homescreen').length) {
            windowHeight = screen.height;
            $('.page-content, #page').css('min-height', windowHeight);
        }
        if (!$('.is-on-homescreen').length) {
            windowHeight = window.innerHeight
            $('.page-content, #page').css('min-height', windowHeight);
        }

        $('[data-card-height]').each(function () {
            const cardHeight = $(this).data('card-height') as string;
            const headerHeight = $('.header').height() as number;
            const footerHeight = $('#footer-bar').height() as number;
            $(this).css('height', cardHeight);
            if (cardHeight == "cover") {
                if (header.length && StickyTheme.instance.menuFooter.length) {
                    $(this).css('height', windowHeight - headerHeight - footerHeight)
                    $('.map-full, .map-full iframe').css('height', windowHeight - headerHeight - footerHeight + 14)
                } else {
                    $(this).css('height', windowHeight)
                    $('.map-full, .map-full iframe').css('height', windowHeight)
                }
            }
        });
    }

    private initInputs(ele?: HTMLElement) {
        /* eslint-disable no-useless-escape */
        if (!this.fullInit) {
            const emailValidator = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
            const phoneValidator = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;
            const nameValidator = /[A-Za-z]{2}[A-Za-z]*[ ]?[A-Za-z]*/;
            const passwordValidator = /[A-Za-z]{2}[A-Za-z]*[ ]?[A-Za-z]*/;
            const urlValidator = /^(http|https)?:\/\/[a-zA-Z0-9-\.]+\.[a-z]{2,4}/;
            const textareaValidator = /[A-Za-z]{2}[A-Za-z]*[ ]?[A-Za-z]*/;
            const validIcon = "<i class='fa fa-check color-green1-dark'><fa icon='check' /></i>";
            const invalidIcon = "<i class='fa fa-exclamation-triangle color-red2-light'></i>";

            $(document.body).on("focusin keyup", ".input-required input, .input-required select, .input-required textarea", function () {
                const spanValue = $(this).parent().find("span").text();
                if ($(this).val() != spanValue && $(this).val() != "") {
                    $(this).parent().find("span").addClass("input-style-1-active").removeClass("input-style-1-inactive");
                }
                if ($(this).val() === "") {
                    $(this).parent().find("span").removeClass("input-style-1-inactive input-style-1-active");
                }
            });
            $(document.body).on("focusout", ".input-required input, .input-required select, .input-required textarea", function () {
                const spanValue = $(this).parent().find("span").text();
                if ($(this).val() === "") {
                    $(this).parent().find("span").removeClass("input-style-1-inactive input-style-1-active");
                }
                $(this).parent().find("span").addClass("input-style-1-inactive")
            });
            $(document.body).on("focusout", ".input-required select", function () {
                const getValue = ($(this)[0] as HTMLInputElement).value;
                if (getValue === "default") {
                    $(this).parent().find("em").html(invalidIcon)
                    $(this).parent().find("span").removeClass("input-style-1-inactive input-style-1-active");
                }
                if (getValue != "default") {
                    $(this).parent().find("em").html(validIcon)
                }
            });
            $(document.body).on("focusout", ".input-required input[type=\"email\"]", function () { if (emailValidator.test($(this).val() as string)) { $(this).parent().find("em").html(validIcon); } else { if ($(this).val() === "") { $(this).parent().find("em").html("(required)"); } else { $(this).parent().find("em").html(invalidIcon); } } });
            $(document.body).on("focusout", ".input-required input[type=\"tel\"]", function () { if (phoneValidator.test($(this).val() as string)) { $(this).parent().find("em").html(validIcon); } else { if ($(this).val() === "") { $(this).parent().find("em").html("(required)"); } else { $(this).parent().find("em").html(invalidIcon); } } });
            $(document.body).on("focusout", ".input-required input[type=\"password\"]", function () { if (passwordValidator.test($(this).val() as string)) { $(this).parent().find("em").html(validIcon); } else { if ($(this).val() === "") { $(this).parent().find("em").html("(required)"); } else { $(this).parent().find("em").html(invalidIcon); } } });
            $(document.body).on("focusout", ".input-required input[type=\"url\"]", function () { if (urlValidator.test($(this).val() as string)) { $(this).parent().find("em").html(validIcon); } else { if ($(this).val() === "") { $(this).parent().find("em").html("(required)"); } else { $(this).parent().find("em").html(invalidIcon); } } });
            $(document.body).on("focusout", ".input-required input[type=\"name\"]", function () { if (nameValidator.test($(this).val() as string)) { $(this).parent().find("em").html(validIcon); } else { if ($(this).val() === "") { $(this).parent().find("em").html("(required)"); } else { $(this).parent().find("em").html(invalidIcon); } } });
            $(document.body).on("focusout", ".input-required input[type=\"text\"]", function () {
                if (($(this).val() as string)?.length) {
                    $(this).parent().find("em").html(validIcon);
                } else {
                    if ($(this).val() === "") {
                        //$(this).parent().find("em").html("(required)"); } else { 
                        $(this).parent().find("em").html(invalidIcon);
                    }
                }
            });
            $(document.body).on("focusout", ".input-required textarea", function () { if (textareaValidator.test($(this).val() as string)) { $(this).parent().find("em").html(validIcon); } else { if ($(this).val() === "") { $(this).parent().find("em").html("(required)"); } else { $(this).parent().find("em").html(invalidIcon); } } });

            //Set Today Date to Date Inputs
            Date.prototype.toDateInputValue = (function () {
                const local = new Date(this);
                local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
                return local.toJSON().slice(0, 10);
            });
        }
        $("input[type=\"date\"]").val(new Date().toDateInputValue());
    }

    private initEnvironment(ele?: HTMLElement) {

        //Detect Mobile OS//
        const isMobile = {
            android: function () { return navigator.userAgent.match(/Android/i); },
            iOS: function () { return navigator.userAgent.match(/iPhone|iPad|iPod/i); },
            windows: function () { return navigator.userAgent.match(/IEMobile/i); },
            any: function () { return (isMobile.android() || isMobile.iOS() || isMobile.windows()); }
        };
        if (!isMobile.any()) {
            $("body").addClass("is-not-ios");
            $(".show-ios, .show-android").addClass("disabled");
            $(".show-no-device").removeClass("disabled");
        }
        if (isMobile.android()) {
            $("body").addClass("is-not-ios");
            $("head").append("<meta name=\"theme-color\" content=\"#FFFFFF\"> />");
            $(".show-android").removeClass("disabled");
            $(".show-ios, .show-no-device, .simulate-android, .simulate-iphones").addClass("disabled");
        }
        if (isMobile.iOS()) {
            $("body").addClass("is-ios");
            $(".show-ios").removeClass("disabled");
            $(".show-android, .show-no-device, .simulate-android, .simulate-iphones").addClass("disabled");
        }

    }

    private initShareMenu(ele?: HTMLElement) {
        //Sharing
        const share_link = window.location.href;
        const share_title = document.title;
        $(".shareToFacebook", ele).prop("href", "https://www.facebook.com/sharer/sharer.php?u=" + share_link)
        $(".shareToLinkedIn", ele).prop("href", "https://www.linkedin.com/shareArticle?mini=true&url=" + share_link + "&title=" + share_title + "&summary=&source=")
        $(".shareToTwitter", ele).prop("href", "https://twitter.com/home?status=" + share_link)
        $(".shareToPinterest", ele).prop("href", "https://pinterest.com/pin/create/button/?url=" + share_link)
        $(".shareToWhatsApp", ele).prop("href", "whatsapp://send?text=" + share_link)
        $(".shareToMail", ele).prop("href", "mailto:?body=" + share_link)
    }

    private initBack2TopCopyright(ele?: HTMLElement) {

        //Copyright Year 
        const copyrightYear = $(".copyright-year, #copyright-year", ele);
        const dteNow = new Date();
        const intYear = dteNow.getFullYear();
        copyrightYear.html(intYear.toString());
        if (this.fullInit) { return; }

        //Back to top Badge
        $(document.body).on("click", ".back-to-top, [data-back-to-top], .back-to-top-badge, .back-to-top-icon", function (e) {
            e.preventDefault();
            $("html, body, .page-content").animate({
                scrollTop: 0
            }, 350);
            return false;
        });
    }

    private initScrollWatch(ele?: HTMLElement) {

        //Scroll Ads
        const scrollAd = $(".scroll-ad");
        function show_scroll_ad() { scrollAd.addClass("scroll-ad-visible"); }
        function hide_scroll_ad() { scrollAd.removeClass("scroll-ad-visible"); }

        const backToTopBadge = $(".back-to-top-badge, .back-to-top-icon");
        function show_back_to_top_badge() { backToTopBadge.addClass("back-to-top-visible"); }
        function hide_back_to_top_badge() { backToTopBadge.removeClass("back-to-top-visible"); }

        if (this.fullInit) { return; }
        $(window).on("scroll", function () {
            const total_scroll_height = document.body.scrollHeight;
            const top = $(this).scrollTop() as number;
            const windowY = $(window).height() as number;
            const inside_header = top <= 150;
            const passed_header = top >= 0; //250
            const passed_header2 = top >= 150; //250
            const footer_reached = (top >= (total_scroll_height - (windowY + 300)));

            if (inside_header === true) {
                hide_back_to_top_badge();
                hide_scroll_ad();
                $(".header-auto-show").removeClass("header-active");

            }
            else if (passed_header === true) {
                show_back_to_top_badge();
                show_scroll_ad();
                $(".header-auto-show").addClass("header-active");
            }
            if (footer_reached == true) {
                hide_back_to_top_badge();
                hide_scroll_ad();
            }
        });

    }
}










//     //Text Resizer
//     $(".text-size-increase").click(function () { $(".text-size-changer *").css("font-size", "+=1"); });
//     $(".text-size-decrease").click(function () { $(".text-size-changer *").css("font-size", "-=1"); });
//     $(".text-size-default").click(function () { $(".text-size-changer *").css("font-size", ""); });

//     //Search Menu Functions
//     function search_menu() {
//         $("[data-search]").on("keyup", function () {
//             var searchVal = $(this).val();
//             if (searchVal != "") {
//                 $(".search-header a").removeClass("disabled");
//                 $(".search-trending").addClass("disabled");
//                 $(".search-results").removeClass("disabled-search-list");
//                 $("[data-filter-item]").addClass("disabled-search-item");
//                 $("[data-filter-item][data-filter-name*=\"" + searchVal.toLowerCase() + "\"]").removeClass("disabled-search-item");
//             } else {
//                 $(".search-header a").removeClass("disabled");
//                 $(".search-trending").removeClass("disabled");
//                 $(".search-results").addClass("disabled-search-list");
//                 $("[data-filter-item]").removeClass("disabled-search-item");
//             }

//             var search_results_error = $(".search-no-results");
//             var search_results_active = $(".search-results").find(".search-result-list.disabled-search-item");
//             if (search_results_active.length) { search_results_error.removeClass("disabled"); } else { search_results_error.addClass("disabled"); }
//         });
//         return false;
//     }
//     search_menu();
//     //Menu Search Values//
//     $(".search-trending a").on("click", function () {
//         var e = jQuery.Event("keydown");
//         e.which = 32
//         $(".search-trending").addClass("disabled");
//         var search_value = $(this).find("span").text().toLowerCase();
//         $(".search-header a").removeClass("disabled");
//         $(".search-header input").val(search_value);
//         $(".search-results").removeClass("disabled-search-list");
//         $("[data-filter-item]").addClass("disabled-search-item");
//         $("[data-filter-item][data-filter-name*=\"" + search_value.toLowerCase() + "\"]").removeClass("disabled-search-item");
//         $(".menu-search-trending").addClass("disabled-search-item");
//         return false;
//     });

//     $(".search-header a").on("click", function () {
//         $(".search-trending").removeClass("disabled");
//         $(".menu-search-trending").removeClass("disabled-search-item");
//         $(".search-results").addClass("disabled-search-list");
//         $(".search-header input").val("");
//         $(this).addClass("disabled");
//         return false;
//     });









//     //Working Hours 
//     var businessHours = $(".business-hours");
//     function activate_business_hours() {
//         if (businessHours.length) {
//             var getTime = new Date(Date.now());
//             var getDay = "day-" + (new Date().toLocaleDateString("en", { weekday: "long" })).toLowerCase();
//             var timeNow = getTime.getHours() + ":" + getTime.getMinutes();
//             var currentWorkDay = $("." + getDay);
//             var closedMessage = businessHours.data("closed-message").toString();
//             var closedMessageUnder = businessHours.data("closed-message-under").toString();
//             var openedMessage = businessHours.data("opened-message").toString();
//             var openedMessageUnder = businessHours.data("opened-message-under").toString();

//             var mondayOpen = $("[data-monday]").data("open");
//             var mondayClose = $("[data-monday]").data("close");
//             var mondayTime = "'Monday' : ['" + mondayOpen + "','" + mondayClose + "'],"


//             $(".business-hours").openingTimes({
//                 //SET OPENING HOURS BELOW
//                 openingTimes: {
//                     "Monday": ["08:00", "17:00"],
//                     "Tuesday": ["08:00", "17:30"],
//                     "Wednesday": ["08:00", "17:00"],
//                     "Thursday": ["08:00", "17:00"],
//                     "Friday": ["09:00", "18:55"],
//                     "Saturday": ["09:00", "12:00"]
//                     //Sunday removed, that means it's closed.
//                 },
//                 openClass: "bg-green1-dark is-business-opened",
//                 closedClass: "bg-red2-dark is-business-closed"
//             });
//             if (businessHours.hasClass("is-business-opened")) {
//                 $(".show-business-opened").removeClass("disabled");
//                 $(".show-business-closed").addClass("disabled");
//                 businessHours.find("h1").html(openedMessage);
//                 businessHours.find("p").html(openedMessageUnder);
//                 businessHours.find("#business-hours-mail").remove();
//                 currentWorkDay.addClass("bg-green1-dark");
//             } else {
//                 $(".show-business-opened").addClass("disabled");
//                 $(".show-business-closed").removeClass("disabled");
//                 businessHours.find("h1").html(closedMessage);
//                 businessHours.find("p").html(closedMessageUnder);
//                 businessHours.find("#business-hours-call").remove();
//                 currentWorkDay.addClass("bg-red2-dark");
//             }

//             currentWorkDay.find("p").addClass("color-white");
//         };
//     }
//     if (businessHours.length) { activate_business_hours() }

//     //Adding added-to-homescreen class to be targeted when used as PWA.
//     function ath() {
//         (function (a, b, c) {
//             if (c in b && b[c]) {
//                 var d, e = a.location,
//                     f = /^(a|html)$/i;
//                 a.addEventListener("click", function (a) {
//                     d = a.target;
//                     while (!f.test(d.nodeName)) d = d.parentNode;
//                     "href" in d && (d.href.indexOf("http") || ~d.href.indexOf(e.host)) && (a.preventDefault(), e.href = d.href)
//                 }, !1);
//                 $(".add-to-home").addClass("disabled");
//                 $("body").addClass("is-on-homescreen");
//             }
//         })(document, window.navigator, "standalone")
//     }
//     ath();

//     //Add to Home Banners
//     $(".simulate-android-badge").on("click", function () { $(".add-to-home").removeClass("add-to-home-ios").addClass("add-to-home-visible add-to-home-android"); });
//     $(".simulate-iphone-badge").on("click", function () { $(".add-to-home").removeClass("add-to-home-android").addClass("add-to-home-visible add-to-home-ios"); });
//     $(".add-to-home").on("click", function () { $(".add-to-home").removeClass("add-to-home-visible"); })
//     $(".simulate-android-banner").on("click", function () { $("#menu-install-pwa-android, .menu-hider").addClass("menu-active") })
//     $(".simulate-ios-banner").on("click", function () { $("#menu-install-pwa-ios, .menu-hider").addClass("menu-active") })


//     //Show Map
//     $(".show-map, .hide-map").on("click", function () {
//         $(".map-full .caption").toggleClass("deactivate-map");
//         $(".map-but-1, .map-but-2").toggleClass("deactivate-map");
//         $(".map-full .hide-map").toggleClass("activate-map");
//     });


//     //Reading Time
//     $("#reading-progress-text").each(function (i) {
//         var readingWords = $(this).text().split(" ").length;
//         var readingMinutes = Math.floor(readingWords / 250);
//         var readingSeconds = readingWords % 60
//         $(".reading-progress-words").append(readingWords);
//         $(".reading-progress-time").append(readingMinutes + ":" + readingSeconds);
//     });

//     //Timed Ads
//     if ($("[data-auto-show-ad]").length) {
//         var time = $("[data-auto-show-ad]").data("auto-show-ad");
//         setTimeout(function () {
//             $("[data-auto-show-ad]").trigger("click");
//         }, time * 1000);
//     }
//     $("[data-timed-ad]").on("click", function () {
//         var counter = $(this).data("timed-ad");
//         var adwin = $("#" + $(this).data("menu"));
//         menuHider.addClass("no-click");
//         adwin.find(".ad-time-close").addClass("no-click");
//         adwin.find(".ad-time-close i").addClass("disabled");
//         adwin.find(".ad-time-close span").removeClass("disabled");

//         var interval = setInterval(function () {
//             counter--;
//             // Display 'counter' wherever you want to display it.
//             if (counter <= 0) {
//                 menuHider.removeClass("no-click");
//                 adwin.find(".ad-time-close").removeClass("no-click");
//                 adwin.find(".ad-time-close i").removeClass("disabled");
//                 adwin.find(".ad-time-close span").addClass("disabled");
//                 clearInterval(interval);
//                 return;
//             } else {
//                 adwin.find(".ad-time-close span").html(counter);
//             }
//         }, 1000);
//     });

//     //Countdown
//     function countdown(dateEnd) {
//         var timer, years, days, hours, minutes, seconds;
//         dateEnd = new Date(dateEnd);
//         dateEnd = dateEnd.getTime();
//         if (isNaN(dateEnd)) { return; }
//         timer = setInterval(calculate, 1);
//         function calculate() {
//             var dateStart = new Date();
//             var dateStart = new Date(dateStart.getUTCFullYear(), dateStart.getUTCMonth(), dateStart.getUTCDate(), dateStart.getUTCHours(), dateStart.getUTCMinutes(), dateStart.getUTCSeconds());
//             var timeRemaining = parseInt((dateEnd - dateStart.getTime()) / 1000)
//             if (timeRemaining >= 0) {
//                 years = parseInt(timeRemaining / 31536000);
//                 timeRemaining = (timeRemaining % 31536000);
//                 days = parseInt(timeRemaining / 86400);
//                 timeRemaining = (timeRemaining % 86400);
//                 hours = parseInt(timeRemaining / 3600);
//                 timeRemaining = (timeRemaining % 3600);
//                 minutes = parseInt(timeRemaining / 60);
//                 timeRemaining = (timeRemaining % 60);
//                 seconds = parseInt(timeRemaining);
//                 if ($(".countdown").length) {
//                     $(".countdown #years")[0].innerHTML = parseInt(years, 10);
//                     $(".countdown #days")[0].innerHTML = parseInt(days, 10);
//                     $(".countdown #hours")[0].innerHTML = ("0" + hours).slice(-2);
//                     $(".countdown #minutes")[0].innerHTML = ("0" + minutes).slice(-2);
//                     $(".countdown #seconds")[0].innerHTML = ("0" + seconds).slice(-2);
//                 }
//             } else { return; }
//         }

//         function display(days, hours, minutes, seconds) { }
//     }
//     countdown("01/19/2030 03:14:07 AM");

//     //Accordion Icons
//     $(".accordion-btn").on("click", function () {
//         $(this).addClass("no-click");
//         $(".accordion-icon").removeClass("rotate-180");
//         if ($(this).attr("aria-expanded") == "true") {
//             $(this).parent().find(".accordion-icon").removeClass("rotate-180");
//         } else {
//             $(this).parent().find(".accordion-icon").addClass("rotate-180");
//         }
//         setTimeout(function () { $(".accordion-btn").removeClass("no-click"); }, 250);
//     })

//     //Caption Hovers
//     $(".caption-scale").unbind().bind("mouseenter mouseleave touchstart touchend", function () { $(this).find("img").toggleClass("caption-scale-image"); });
//     $(".caption-grayscale").unbind().bind("mouseenter mouseleave touchstart touchend", function () { $(this).find("img").toggleClass("caption-grayscale-image"); });
//     $(".caption-rotate").unbind().bind("mouseenter mouseleave touchstart touchend", function () { $(this).find("img").toggleClass("caption-rotate-image"); });
//     $(".caption-blur").unbind().bind("mouseenter mouseleave touchstart touchend", function () { $(this).find("img").toggleClass("caption-blur-image"); });
//     $(".caption-hide").unbind().bind("mouseenter mouseleave touchstart touchend", function () { $(this).find(".caption-center, .caption-bottom, .caption-top, .caption-overlay").toggleClass("caption-hide-image"); });

//     //File Upload
//     var uploadFile = $(".upload-file");
//     function activate_upload_file() {
//         function readURL(input) {
//             if (input.files && input.files[0]) {
//                 var reader = new FileReader();
//                 reader.onload = function (e) {
//                     $(".file-data img").attr("src", e.target.result);
//                     $(".file-data img").attr("class", "img-fluid rounded-xs mt-4");
//                 }
//                 reader.readAsDataURL(input.files[0]);
//             }
//         }
//         $(".upload-file").change(function (e) {
//             readURL(this);
//             var fileName = e.target.files[0].name;
//             $(".upload-file-data").removeClass("disabled");
//             $(".upload-file-name").html(e.target.files[0].name)
//             $(".upload-file-modified").html(e.target.files[0].lastModifiedDate);
//             $(".upload-file-size").html(e.target.files[0].size / 1000 + "kb")
//             $(".upload-file-type").html(e.target.files[0].type)
//         });
//     };
//     if (uploadFile.length) { activate_upload_file(); }

//     //Task List Check on Click
//     var todo = $(".todo-list");
//     function activate_todo_list() {
//         $(".todo-list a").each(function () {
//             if ($(this).find(".todo-icon").hasClass("far fa-square")) { $(this).removeClass("opacity-70"); } else { $(this).addClass("opacity-70"); }
//         })
//         $(".todo-list a").on("click", function () {
//             $(this).find(".todo-icon").toggleClass("far fa-square fa fa-check-square color-green1-dark");
//             if ($(this).find(".todo-icon").hasClass("far fa-square")) { $(this).removeClass("opacity-70"); } else { $(this).addClass("opacity-70"); }
//         })
//     }
//     if (todo.length) { activate_todo_list(); }




//     //Geolocation
//     var geoLocation = $(".get-location");
//     function activate_geolocation() {
//         if ("geolocation" in navigator) {
//             $(".location-support").html("Your browser and device <strong class=\"color-green2-dark\">support</strong> Geolocation.");
//         } else {
//             $(".location-support").html("Your browser and device <strong class=\"color-red2-dark\">support</strong> Geolocation.");
//         }
//         function geoLocate() {
//             const locationCoordinates = document.querySelector(".location-coordinates");
//             function success(position) {
//                 const latitude = position.coords.latitude;
//                 const longitude = position.coords.longitude;
//                 locationCoordinates.innerHTML = "<strong>Longitude:</strong> " + longitude + "<br><strong>Latitude:</strong> " + latitude;

//                 var mapL1 = "http://maps.google.com/maps?q=";
//                 var mapL2 = latitude + ",";
//                 var mapL3 = longitude;
//                 var mapL4 = "&z=18&t=h&output=embed"
//                 var mapL5 = "&z=18&t=h"
//                 var mapLinkEmbed = mapL1 + mapL2 + mapL3 + mapL4;
//                 var mapLinkAddress = mapL1 + mapL2 + mapL3 + mapL5;

//                 $(".location-map").after("<iframe class=\"location-map\" src=\"" + mapLinkEmbed + "\"></iframe> <div class=\"clearfix\"></div>");
//                 $(".location-map").parent().after(" <a href=" + mapLinkAddress + " class=\"btn btn-full btn-m bg-red2-dark rounded-xs text-uppercase font-900 mb-n1 mt-3\">View on Google Maps</a>");
//             }
//             function error() {
//                 locationCoordinates.textContent = "Unable to retrieve your location";
//             }
//             if (!navigator.geolocation) {
//                 locationCoordinates.textContent = "Geolocation is not supported by your browser";
//             } else {
//                 locationCoordinates.textContent = "Locating";
//                 navigator.geolocation.getCurrentPosition(success, error);
//             }
//         }
//         $(".get-location").on("click", function () {
//             $(this).addClass("disabled");
//             geoLocate();
//         });
//     };
//     if (geoLocation.length) { activate_geolocation(); }

//     //Adding Offline Alerts
//     var offlineAlerts = $(".offline-message");
//     if (!offlineAlerts.length) {
//         $("body").append("<p class=\"offline-message bg-red2-dark color-white center-text uppercase ultrabold\">No internet connection detected</p> ");
//         $("body").append("<p class=\"online-message bg-green1-dark color-white center-text uppercase ultrabold\">You are back online</p>");
//     }

//     //Offline Function Show
//     function isOffline() {
//         $(".offline-message").addClass("offline-message-active");
//         $(".online-message").removeClass("online-message-active");
//         setTimeout(function () { $(".offline-message").removeClass("offline-message-active"); }, 2000);
//     }

//     //Online Function Show
//     function isOnline() {
//         $(".online-message").addClass("online-message-active");
//         $(".offline-message").removeClass("offline-message-active");
//         setTimeout(function () { $(".online-message").removeClass("online-message-active"); }, 2000);
//     }

//     $(".simulate-offline").on("click", function () { isOffline(); })
//     $(".simulate-online").on("click", function () { isOnline(); })

//     //Check if Online / Offline
//     function updateOnlineStatus(event) {
//         var condition = navigator.onLine ? "online" : "offline";
//         isOnline();
//         console.log("Connection: Online");
//     }
//     function updateOfflineStatus(event) {
//         isOffline();
//         console.log("Connection: Offline");
//     }
//     window.addEventListener("online", updateOnlineStatus);
//     window.addEventListener("offline", updateOfflineStatus);

//     //QR Generator
//     var generateQR = $(".generate-qr-result, .generate-qr-auto");
//     function activate_qr_generator() {
//         //QR Code Generator 
//         var qr_auto_link = window.location.href;
//         var qr_api_address = "https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=";

//         $(".generate-qr-auto").attr("src", qr_api_address + qr_auto_link)
//         $(".generate-qr-button").on("click", function () {
//             if ($(this).parent().find(".fa").hasClass("fa-exclamation-triangle")) {
//                 console.log("Invalid URL");
//             } else {
//                 var get_qr_url = $(".generate-qr-input").val();
//                 if (!get_qr_url == "") {
//                     $(".generate-qr-result").empty();
//                     setTimeout(function () {
//                         $(".generate-qr-result").append("<img class=\"mx-auto polaroid-effect shadow-l mt-4 delete-qr\" width=\"200\" src=\"" + qr_api_address + get_qr_url + "\" alt=\"img\"><p class=\"font-11 text-center mb-0\">" + get_qr_url + "</p>")
//                     }, 30);
//                 }
//             }
//         });
//     }
//     if (generateQR.length) { activate_qr_generator(); }

//     //Vibrate Buttons
//     var vibrateButton = $("[data-vibrate]");
//     function activate_vibration() {
//         $("[data-vibrate]").on("click", function () { var vibrateTime = $(this).data("vibrate"); window.navigator.vibrate(vibrateTime); });
//         $(".start-vibrating").on("click", function () { var vibrateTimeInput = $(".vibrate-demo").val(); window.navigator.vibrate(vibrateTimeInput); })
//         $(".stop-vibrating").on("click", function () { window.navigator.vibrate(0); $(".vibrate-demo").val(""); });
//     }
//     if (vibrateButton.length) {
//         activate_vibration();
//     }





//     //LightBox
//     $("[data-lightbox]").addClass("default-link");
//     lightbox.option({ alwaysShowNavOnTouchDevices: true, "resizeDuration": 200, "wrapAround": false })
//     $("#lightbox").hammer().on("swipe", function (event) {
//         if (event.gesture.direction === 4) {
//             $("#lightbox a.lb-prev").trigger("click");
//         } else if (event.gesture.direction === 2) {
//             $("#lightbox a.lb-next").trigger("click");
//         }
//     });

//     //Filterable
//     if ($(".gallery-filter").length > 0) { $(".gallery-filter").filterizr(); $(".gallery-filter-active").addClass("color-highlight"); }
//     $(".gallery-filter-controls li").on("click", function () {
//         $(".gallery-filter-controls li").removeClass("gallery-filter-active color-highlight");
//         $(this).addClass("gallery-filter-active color-highlight");
//     });

//     //Gallery Views // Added in 2.0
//     var galleryViews = $(".gallery-views");
//     var galleryViewControls = $(".gallery-view-controls a");
//     var galleryView1 = $(".gallery-view-1-activate");
//     var galleryView2 = $(".gallery-view-2-activate");
//     var galleryView3 = $(".gallery-view-3-activate");

//     galleryView1.on("click", function () {
//         galleryViewControls.removeClass("color-highlight");
//         $(this).addClass("color-highlight");
//         galleryViews.removeClass().addClass("gallery-views gallery-view-1");
//     });
//     galleryView2.on("click", function () {
//         galleryViewControls.removeClass("color-highlight");
//         $(this).addClass("color-highlight");
//         galleryViews.removeClass().addClass("gallery-views gallery-view-2");
//     });
//     galleryView3.on("click", function () {
//         galleryViewControls.removeClass("color-highlight");
//         $(this).addClass("color-highlight");
//         galleryViews.removeClass().addClass("gallery-views gallery-view-3");
//     });


//     //Search
//     var search = $("[data-search]");
//     function activate_search() {
//         search.on("keyup", function () {
//             var searchVal = $(this).val();
//             var filterItems = $(this).parent().parent().find("[data-filter-item]");
//             if (searchVal != "") {
//                 $(".search-results").removeClass("disabled-search-list");
//                 $("[data-filter-item]").addClass("disabled-search");
//                 $("[data-filter-item][data-filter-name*=\"" + searchVal.toLowerCase() + "\"]").removeClass("disabled-search");
//             } else {
//                 $(".search-results").addClass("disabled-search-list");
//                 $("[data-filter-item]").removeClass("disabled-search");
//             }
//         });
//     }
//     if (search.length) { activate_search(); }





//     //Local Error Message
//     if (window.location.protocol === "file:") { $("a").on("mouseover", function () { console.log("You are seeing these errors because your file is on your local computer. For real life simulations please use a Live Server or a Local Server such as AMPPS or WAMPP or simulate a  Live Preview using a Code Editor like http://brackets.io (it's 100% free) - PWA functions and AJAX Page Transitions will only work in these scenarios."); }); }


