<template>
  <div class="header header-fixed header-logo-center">
    <router-link to="/" class="header-title"
      >Word Warden
      <!-- <logo-icon
        class="pb-2"
        icon-color="black"
        width="32"
        height="32"
        :animate="true"
      /> -->
    </router-link>

    <a
      @click.prevent="$router.go(-1)"
      v-if="showBackBtn"
      data-back-button
      class="header-icon header-icon-1"
      ><i><fa icon="arrow-left" /></i
    ></a>
    <a href="#" v-else class="header-icon header-icon-1" data-menu="main-menu"
      ><i><fa icon="bars" /></i
    ></a>

    <!-- <logo-icon icon-color="black" width="32" height="32" /> -->

    <a href="#" v-if="offline" class="header-icon header-icon-3">
      <i><fa :icon="['far', 'signal-slash']" /></i>
    </a>
    <a href="#" data-toggle-theme class="header-icon header-icon-4">
      <i>
        <fa icon="lightbulb" />
      </i>
    </a>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import LogoIcon from "@/components/icons/logo.vue";
@Options({
  name: "HeaderBar",
  components: {
    LogoIcon,
  },
})
export default class HeaderBar extends Vue {
  private offline = false;
  private backgroundWork = true;

  public get showBackBtn() {
    return this.$router.currentRoute.value.fullPath.lastIndexOf("/") > 0;
  }

  public created() {
    this.offline = !navigator.onLine;
    window.addEventListener("offline", () => (this.offline = true));
    window.addEventListener("online", () => (this.offline = false));
  }
}
</script>