<template>
  <overscroll-blocker>
    <slot name="headers">
      <header-bar />
    </slot>
    <div class="page-content header-clear-medium">
      <slot></slot>
    </div>
    <div id="ww-menus">      
      <all-menus />
      <main-menu />
      <slot name="add-menus" />
    </div>
  </overscroll-blocker>
</template>

<script lang="ts">
import AllMenus from "@/components/all-menus.vue";
import HeaderBar from "@/components/header-bar.vue";
import MainMenu from "@/components/main-menu.vue";
import { Options, Vue } from "vue-class-component";
import OverscrollBlocker from './overscroll-blocker.vue';

@Options({
  name: "StickyPage",
  components: {
    HeaderBar,
    AllMenus,
    MainMenu,
    OverscrollBlocker
  },
})
export default class StickyPage extends Vue {
  public async mounted() {
    setTimeout(()=>window.ww.theme.initAll()); //TODO:
  }
}
</script>