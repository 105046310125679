// /* eslint-disable no-console */

import { register } from "register-service-worker";
import { LogService } from "@/services/logService";
const ls = new LogService();

//if (process.env.NODE_ENV === "production") {
ls.info('registering sw');
register(`${process.env.BASE_URL}service-worker.js`, {
    ready(): void {
        ls.info(
            "App is being served from cache by a service worker.\n" +
            "For more details, visit https://goo.gl/AFskqB"
        );
    },
    registered(): void {
        ls.info("Service worker has been registered.");
    },
    cached(): void {
        ls.info("Content has been cached for offline use.");
    },
    updatefound(): void {
        ls.info("New content is downloading.");
    },
    updated(): void {
        ls.info("New content is available; please refresh.");
    },
    offline(): void {
        ls.info("No internet connection found. App is running in offline mode.");
    },
    error(error: Error): void {
        ls.error("Error during service worker registration:", error);
    }
});
//}
