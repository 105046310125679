<template>
  <div class="bs-vuetr-error">
    <router-view v-slot="{ Component }">
      <!-- <transition
        :name="transitionName"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
        mode="out-in"
      > -->
        <component :is="Component" />
      <!-- </transition> -->
    </router-view>
    <sticky-snackbar />
    <sticky-toast />
  </div>
</template>
<script lang="ts">
//TODO: maybe doing too much here
import $ from "jquery";
import { Options, Vue } from "vue-class-component";
//import { BackgroundSync } from "@/services/backgroundSync";
// import { Project } from "@/shared/projects";
// import { SettingsFactory } from "@/shared/config";
import UserService  from "./services/userService";
import StickySnackbar from "@/components/sticky/sticky-snackbar.vue";
import StickyToast from "@/components/sticky/sticky-toast.vue";
@Options({
  components: {
    StickySnackbar,
    StickyToast,
  },
})
export default class App extends Vue {
  public prevHeight = "0";
  public transitionName = "slide-left";
  private us= UserService;
  public async created() {
    //These exist in the dom before app so this is safe here
    $("#wwloader").hide();
    $("#wwloaderTitle").hide();
    $("body").attr("style", null);
    await this.us.init();
    // this.bs = new BackgroundSync();
    // await this.bs.activate();

    this.$router.beforeEach((to, from, next) => {
      let transitionName: string =
        to.meta.transitionName as string || from.meta.transitionName as string;

      if (transitionName === "slide") {
        const toDepth = to.path.split("/").length;
        const fromDepth = from.path.split("/").length;
        transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
      }

      this.transitionName = transitionName || "slide-left";

      next();
    });
  }
  public beforeLeave(element: HTMLElement) {
    this.prevHeight = getComputedStyle(element).height;
  }

  public enter(element: HTMLElement) {
    const { height } = getComputedStyle(element);

    element.style.height = this.prevHeight;

    setTimeout(() => {
      element.style.height = height;
    });
  }

  public afterEnter(element: HTMLElement) {
    element.style.height = "auto";
  }
}
</script>
<style lang="scss">
@import "./src/scss/bootstrap.scss";
@import "./src/scss/style.scss";

.slither-enter-active,
.slither-leave-active {
  transition: transform 3s;
}

.slither-enter,
.slither-leave-to {
  transform: translateX(-100%);
}

.slither-enter-to,
.slither-leave {
  transform: translateX(0);
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-property: height, opacity;
  overflow: hidden;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(20em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-20em, 0);
}
</style>
