
import { defineComponent, PropType, ref } from 'vue';



export default defineComponent({
  props: {
    initialValue: {
      type: Boolean,
      default: false,
    },
    label: {
      required: true,
      type: String,
      validator: (val: string):boolean => {
        return !!val?.length;
      },
    },
    iconClass: {
      required: false,
      type: String,
      default: "font-14 color-highlight",
    },
    icon: {
      required: false,
      type: Object as PropType<string | string[]>,
      default: null,
    },
  },
  name:"StickyToggle",
  setup(props, { emit }) {
    //const { initialValue } = toRefs(props)
    const innerValue = ref(props.initialValue);
    const onClick = function() {
      innerValue.value = !innerValue.value;
      emit("toggled", innerValue);
    };
    return {
      onClick,
      innerValue,
      ...props
    };
  }
  // data() {
  //   return {
  //     innerValue: false,
  //   };
  // },
  // emits:['toggled'],
  // created() {
  //   const dumb = this.label;
  //   this.innerValue = this.initialValue;
  // },
  // methods: {
  //   onClick() {
  //     this.innerValue = !this.innerValue;
  //     this.$emit("toggled", this.innerValue);
  //   },
  // },  
});
