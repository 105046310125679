import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from "../views/Home.vue";
import NotFound from '../views/NotFound.vue';
import Walkthrough from "../views/Walkthrough.vue";
import { trackRouter } from "vue-gtag-next";
import { LogService } from '@/services/logService';
const ls = new LogService();

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/stats",
    name: "Stats",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "stats" */ "../views/Stats.vue")
  },
  {
    path: "/projects",
    name: "Projects",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "projects" */ "../views/Projects.vue")
  },
  {
    path: "/projects/:id",
    name: "ProjectDetails",
    props: true,// This injects the 'id' from the route into projectdetails as a property
    beforeEnter(to, from, next) {
      if (to.params?.id.length !== 36) {//proceeds if true
        ls.warn(`route blocked ${to?.name as string}`, to);
        next(false);
      } else { next(true); }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "projects" */ "../views/ProjectDetails.vue")
  },
  {
    path: "/projects/:id/settings",
    name: "ProjectSettings",
    props: true,
    beforeEnter(to, from, next) {
      if (to.params?.id.length !== 36) {//proceeds if true
        ls.warn(`route blocked ${to?.name as string}`, to);
        next(false);
      } else { next(true); }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "projects" */ "../views/ProjectSettings.vue")
  },
  {
    path: "/projects/:id/stats",
    name: "ProjectStats",
    props: true,
    beforeEnter(to, from, next) {
      if (to.params?.id.length !== 36) {//proceeds if true
        ls.warn(`route blocked ${to?.name as string}`, to);
        next(false);
      } else { next(true); }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "stats" */ "../views/ProjectStats.vue")
  },
  {
    path: "/projects/:id/sessions",
    name: "ProjectSessions",
    props: true,
    beforeEnter(to, from, next) {
      if (to.params?.id.length !== 36) {//proceeds if true
        ls.warn(`route blocked ${to?.name as string}`, to);
        next(false);
      } else { next(true); }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "stats" */ "../views/ProjectSessions.vue")
  },
  {
    path: "/terms",
    name: "Terms",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "smallpages" */ "../views/Terms.vue")
  },
  {
    path: "/privacy",
    name: "Privacy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "smallpages" */ "../views/Privacy.vue")
  },
  {
    path: "/walk",
    name: "Walk",
    component: Walkthrough
  },
  {
    path: "/profile",
    name: "Profile",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "userdetails" */ "../views/Profile.vue")
  },
  {
    path: "/settings",
    name: "Settings",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "smallpages" */ "../views/Settings.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "smallpages" */ "../views/Contact.vue")
  },
  // {
  //   path: "/account/login",
  //   name: "Login",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "auth" */ "../views/account/Login.vue")
  // },
  {
    path: "/register",
    name: "Register",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "auth" */ "../views/account/Register.vue")
  },
  {
    path: "/account/forgot",
    name: "Forgot",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "auth" */ "../views/account/Forgot.vue")
  },
  {
    path: "/account/signin",
    name: "SignIn",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "auth" */ "../views/account/SignIn.vue")
  },
  {
    path: "/debug",
    name: "Debug",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "debug" */ "../views/Debug.vue")
  },
  {
    path: "/subscribe",
    name: "Subscription",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "userdetails" */ "../views/Subscription.vue")
  },
  {
    path: "/pomodoro",
    name: "Pomodoro",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "timers" */ "../views/Pomodoro.vue")
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
  {
    name: "oops",
    path: "/oops/:resource",
    component: NotFound,
    props: true
  },

];

function shouldShowWalk(): boolean { //TODO:
  //if (window.ww.theme.readCookie('hidewalk')) {
    return false;
  //}
  //return true;
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

});
const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
trackRouter(router);
// this works but then we have to define dependencies here, gross
// router.beforeResolve(async (to, from, next) => {
//   await delay(5000);
//   return next(true);

// });
router.beforeEach((to, from, next) => {
  if (to.path === '/walk') {
    next();
    return;
  }

  if (shouldShowWalk()) {
    next('/walk');
  } else {
    next();
  }
});

export default router;