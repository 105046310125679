
// import $ from "jquery";
// import { UserManager, Log, Profile, OidcClientSettings } from "oidc-client";
import "vue3-carousel/dist/carousel.css";
import { Options, Vue } from "vue-class-component";
import router from "@/router";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
// @ is an alias to /src
@Options({
  name: "Walkthrough",
  components: { Carousel, Slide, Pagination, Navigation },
})
export default class WalkthroughView extends Vue {
  public async getStarted() {
    window.ww.theme.createCookie("hidewalk", 1, 1);
    router.push("/");
    //window.location.href ="https://auth.wordwarden.app/account/register"
  }

  public async mounted() {
    window.ww.theme.initAll();
  }
}
