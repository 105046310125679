
//TODO: maybe doing too much here
import $ from "jquery";
import { Options, Vue } from "vue-class-component";
//import { BackgroundSync } from "@/services/backgroundSync";
// import { Project } from "@/shared/projects";
// import { SettingsFactory } from "@/shared/config";
import UserService  from "./services/userService";
import StickySnackbar from "@/components/sticky/sticky-snackbar.vue";
import StickyToast from "@/components/sticky/sticky-toast.vue";
@Options({
  components: {
    StickySnackbar,
    StickyToast,
  },
})
export default class App extends Vue {
  public prevHeight = "0";
  public transitionName = "slide-left";
  private us= UserService;
  public async created() {
    //These exist in the dom before app so this is safe here
    $("#wwloader").hide();
    $("#wwloaderTitle").hide();
    $("body").attr("style", null);
    await this.us.init();
    // this.bs = new BackgroundSync();
    // await this.bs.activate();

    this.$router.beforeEach((to, from, next) => {
      let transitionName: string =
        to.meta.transitionName as string || from.meta.transitionName as string;

      if (transitionName === "slide") {
        const toDepth = to.path.split("/").length;
        const fromDepth = from.path.split("/").length;
        transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
      }

      this.transitionName = transitionName || "slide-left";

      next();
    });
  }
  public beforeLeave(element: HTMLElement) {
    this.prevHeight = getComputedStyle(element).height;
  }

  public enter(element: HTMLElement) {
    const { height } = getComputedStyle(element);

    element.style.height = this.prevHeight;

    setTimeout(() => {
      element.style.height = height;
    });
  }

  public afterEnter(element: HTMLElement) {
    element.style.height = "auto";
  }
}
