
import { Options, Vue } from "vue-class-component";

@Options({
  name: "LogoIcon",
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "black",
    },
    animate: {
      type: Boolean,
      default: false,
    },
  },
})
export default class LogoIcon extends Vue {
  //https://github.com/sdras/vue-sample-svg-icons/blob/master/src/components/IconBase.vue
}
