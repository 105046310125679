
export interface WWSettings {
    /** ms before second init  eg. setTimeout(x, SETTINGS.secondInitDelay);*/
    secondInitDelay: number;
    /**e.g. 'https://www.wordwarden.app/api' */
    apiPath: string;
    externalLoginPath: string;
    loginPath: string;
    fetchWithCredentials: boolean;
    registerPath: string;
    currentVersion: string;
    httpTimeout: number;
    paypal: {
        clientId: string;
        monthlyPlanId: string;
        annualPlanId: string;
        //monthlyPrice: number;
        //annualPrice: number;
    };
}
const SHARED_SETTINGS = {
    httpTimeout: 10000,
    currentVersion: '1.2.0',
    secondInitDelay: 10
}
const DEV_SETTINGS: WWSettings = Object.assign({}, SHARED_SETTINGS, {
    apiPath: 'https://localhost:80/api',
    externalLoginPath: "https://localhost:80/Identity/Account/ExternalLogin?returnUrl=",
    loginPath: "https://localhost:80/Identity/Account/login?returnUrl=",
    registerPath: "https://localhost:80/Identity/Account/register",
    fetchWithCredentials: false,
    paypal: {
        clientId: "AS0N_I_5nT1TeW5-wKbsJjqzcMlG8CkEOtpP3D_XCh_5eLbmu-4e1Mjigw3goItKaKiitOCEbMOWFeDF",
        monthlyPlanId: "P-55Y16201MA773945EMA2554Y",
        annualPlanId: "P-3U036089617547636MA26EMQ"
    }
});

const PROD_SETTINGS: WWSettings = Object.assign({}, SHARED_SETTINGS, {
    apiPath: 'https://www.wordwarden.app/api',
    externalLoginPath: "https://www.wordwarden.app/Identity/Account/ExternalLogin?returnUrl=",
    loginPath: "https://www.wordwarden.app/Identity/Account/login?returnUrl=",
    registerPath: "https://localhost:80/Identity/Account/register",
    fetchWithCredentials: true,
    paypal: {
        clientId: "",
        monthlyPlanId: "",
        annualPlanId: ""
    }
});

export class SettingsFactory {
    public getSettings(): WWSettings {
        const env = process.env.NODE_ENV || 'development';
        if (env === 'development') {
            return DEV_SETTINGS;
        }
        return PROD_SETTINGS;
    }
}