<template>
  <div id="stickymenus">
    <!-- All Menus, Action Sheets, Modals, Notifications, Toasts, Snackbars get Placed outside the <div class="page-content"> -->
    <!-- <div id="error-toast" class="toast toast-tiny toast-top bg-red2-dark" data-delay="3000" data-autohide="true"><i class="fa fa-times mr-3"></i>Error Occured</div> -->

    <!-- Be sure this is on your main visiting page, for example, the index.html page-->
    <!-- Install Prompt for Android -->
    <div
      id="menu-install-pwa-android"
      class="menu menu-box-bottom menu-box-detached rounded-l"
      data-menu-height="350"
      data-menu-effect="menu-parallax"
    >
      <div class="boxed-text-l mt-4">
        <img
          class="rounded-l mb-3"
          src="/app/icons/icon-128x128.png"
          alt="img"
          width="90"
        />
        <h4 class="mt-3">Add Sticky on your Home Screen</h4>
        <p>
          Install Sticky on your home screen, and access it just like a regular
          app. It really is that simple!
        </p>
        <a
          href="#"
          class="
            pwa-install
            btn btn-s
            rounded-s
            shadow-l
            text-uppercase
            font-900
            bg-highlight
            mb-2
          "
          >Add to Home Screen</a
        >
        <br />
        <a
          href="#"
          class="
            pwa-dismiss
            close-menu
            color-gray2-light
            text-uppercase
            font-900
            opacity-60
            font-10
          "
          >Maybe later</a
        >
        <div class="clear"></div>
      </div>
    </div>

    <!-- Install instructions for iOS -->
    <div
      id="menu-install-pwa-ios"
      class="menu menu-box-bottom menu-box-detached rounded-l"
      data-menu-height="320"
      data-menu-effect="menu-parallax"
    >
      <div class="boxed-text-xl mt-4">
        <img class="rounded-l mb-3" src="/logo.svg" alt="img" width="90" />
        <h4 class="mt-3">Add Word Warden on your Home Screen</h4>
        <p class="mb-0 pb-0">
          Install the warden from your dicky on your home screen, and access it
          just like a regular app. Open your Safari menu and tap "Add to Home
          Screen".
        </p>
        <div class="clear"></div>
        <a
          href="#"
          class="
            pwa-dismiss
            close-menu
            color-highlight
            uppercase
            ultrabold
            opacity-80
            top-25
          "
          >Maybe later</a
        >
        <i class="fa-ios-arrow fa fa-caret-down font-40"></i>
      </div>
    </div>

    <!-- All Menus, Action Sheets, Modals, Notifications, Toasts, Snackbars get Placed outside the <div class="page-content"> -->
    <div
      id="menu-settings"
      class="menu menu-box-bottom menu-box-detached rounded-m"
      data-menu-height="308"
    >
      <div class="menu-title">
        <h1>Display Settings</h1>
        <p class="color-highlight">Quick visual customization</p>
        <a href="#" class="close-menu"><fa icon="times"></fa></a>
      </div>
      <div class="divider divider-margins mb-n2"></div>
      <div class="content">
        <!-- <font-awesome-layers full-width class="fa-4x">
          <fa icon="chess-queen" />
          <font-awesome-layers-text
            class="gray8"
            transform="down-2 shrink-8"
            value="Q"
          />
        </font-awesome-layers> -->
        <div class="list-group list-custom-small">
          <sticky-toggle
            data-toggle-theme
            :initial-value="darkMode"
            label="Dark Mode"
            icon="lightbulb"
            @toggled="dark"
            icon-class="bg-yellow1-dark"
          />
        </div>
        <div class="list-group list-custom-large">
          <a data-menu="menu-highlights" href="#">
            <i class="mr-3 bg-green1-dark rounded-s"
              ><fa icon="tint" class="font-12"></fa>
            </i>
            <span>Page Highlight</span>
            <strong>16 Colors Highlights Included</strong>
            <!-- <span class="badge bg-highlight color-white">HOT</span> -->
            <i>
              <fa icon="angle-right" class="font-12"></fa>
            </i>
          </a>
          <a data-menu="menu-backgrounds" href="#" class="border-0">
            <i class="fa font-14 fa-cog bg-blue2-dark rounded-s"
              ><fa icon="cog"
            /></i>
            <span>Background Color</span>
            <strong>10 Page Gradients Included</strong>
            <!-- <span class="badge bg-highlight color-white">NEW</span> -->
            <i><fa icon="angle-right"></fa></i>
          </a>
        </div>
      </div>
    </div>
    <!-- Menu Settings Highlights-->
    <div
      id="menu-highlights"
      class="menu menu-box-bottom menu-box-detached rounded-m"
      data-menu-height="380"
      data-menu-effect="menu-over"
    >
      <div class="menu-title">
        <h1>Highlights</h1>
        <p class="color-highlight">Any Element can have a Highlight Color</p>
        <a href="#" class="close-menu"><fa icon="times"></fa></a>
      </div>
      <div class="divider divider-margins mb-n2"></div>
      <div class="content">
        <div class="highlight-changer">
          <a href="#" data-change-highlight="blue2"
            ><fa icon="circle" class="color-blue2-dark" size="lg"></fa
            ><span class="color-blue2-light">Default</span></a
          >
          <a href="#" data-change-highlight="red2"
            ><fa
              icon="circle"
              class="fa fa-circle color-red1-dark"
              size="lg"
            ></fa
            ><span class="color-red2-light">Red</span></a
          >
          <a href="#" data-change-highlight="orange"
            ><fa
              icon="circle"
              class="fa fa-circle color-orange-dark"
              size="lg"
            ></fa
            ><span class="color-orange-light">Orange</span></a
          >
          <a href="#" data-change-highlight="pink2"
            ><fa
              icon="circle"
              class="fa fa-circle color-pink2-dark"
              size="lg"
            ></fa
            ><span class="color-pink2-light">Pink</span></a
          >
          <a href="#" data-change-highlight="magenta2"
            ><fa
              icon="circle"
              class="fa fa-circle color-magenta2-dark"
              size="lg"
            ></fa
            ><span class="color-magenta2-light">Purple</span></a
          >
          <a href="#" data-change-highlight="aqua"
            ><fa
              icon="circle"
              class="fa fa-circle color-aqua-dark"
              size="lg"
            ></fa
            ><span class="color-aqua-light">Aqua</span></a
          >
          <a href="#" data-change-highlight="teal"
            ><fa
              icon="circle"
              class="fa fa-circle color-teal-dark"
              size="lg"
            ></fa
            ><span class="color-teal-light">Teal</span></a
          >
          <a href="#" data-change-highlight="mint"
            ><fa
              icon="circle"
              class="fa fa-circle color-mint-dark"
              size="lg"
            ></fa
            ><span class="color-mint-light">Mint</span></a
          >
          <a href="#" data-change-highlight="green2"
            ><fa
              icon="circle"
              class="fa fa-circle color-green2-dark"
              size="lg"
            ></fa
            ><span class="color-green2-light">Green</span></a
          >
          <a href="#" data-change-highlight="green1"
            ><fa
              icon="circle"
              class="fa fa-circle color-green1-dark"
              size="lg"
            ></fa
            ><span class="color-green1-light">Grass</span></a
          >
          <a href="#" data-change-highlight="yellow2"
            ><fa
              icon="circle"
              class="fa fa-circle color-yellow2-dark"
              size="lg"
            ></fa
            ><span class="color-yellow2-light">Sunny</span></a
          >
          <a href="#" data-change-highlight="yellow1"
            ><fa
              icon="circle"
              class="fa fa-circle color-yellow1-dark"
              size="lg"
            ></fa
            ><span class="color-yellow1-light">Goldish</span></a
          >
          <a href="#" data-change-highlight="brown1"
            ><fa
              icon="circle"
              class="fa fa-circle color-brown1-dark"
              size="lg"
            ></fa
            ><span class="color-brown1-light">Wood</span></a
          >
          <a href="#" data-change-highlight="dark1"
            ><fa
              icon="circle"
              class="fa fa-circle color-dark1-dark"
              size="lg"
            ></fa
            ><span class="color-dark1-light">Night</span></a
          >
          <a href="#" data-change-highlight="dark2"
            ><fa
              icon="circle"
              class="fa fa-circle color-dark2-dark"
              size="lg"
            ></fa
            ><span class="color-dark2-light">Dark</span></a
          >
          <div class="clearfix"></div>
        </div>
        <a
          href="#"
          data-menu="menu-settings"
          class="
            btn btn-full btn-m
            rounded-sm
            bg-highlight
            shadow-xl
            text-uppercase
            font-900
            mt-4
          "
          >Back to Settings</a
        >
      </div>
    </div>
    <!-- Menu Settings Backgrounds-->
    <div
      id="menu-backgrounds"
      class="menu menu-box-bottom menu-box-detached rounded-m"
      data-menu-height="310"
      data-menu-effect="menu-over"
    >
      <div class="menu-title">
        <h1>Backgrounds</h1>
        <p class="color-highlight">Change Page Color Behind Content Boxes</p>
        <a href="#" class="close-menu"><i class="fa fa-times"></i></a>
      </div>
      <div class="divider divider-margins mb-n2"></div>
      <div class="content">
        <div class="background-changer">
          <a href="#" data-change-background="none"
            ><i class="bg-theme"></i><span>Default</span></a
          >
          <a href="#" data-change-background="plum"
            ><i class="body-plum"></i
            ><span class="color-plum-dark">Plum</span></a
          >
          <a href="#" data-change-background="magenta3"
            ><i class="body-magenta3"></i
            ><span class="color-magenta3-dark">Magenta</span></a
          >
          <a href="#" data-change-background="dark3"
            ><i class="body-dark3"></i
            ><span class="color-dark3-dark">Dark</span></a
          >
          <a href="#" data-change-background="violet"
            ><i class="body-violet"></i
            ><span class="color-violet-dark">Violet</span></a
          >
          <a href="#" data-change-background="red3"
            ><i class="body-red3"></i
            ><span class="color-red3-dark">Red</span></a
          >
          <a href="#" data-change-background="green3"
            ><i class="body-green3"></i
            ><span class="color-green3-dark">Green</span></a
          >
          <a href="#" data-change-background="sky"
            ><i class="body-sky"></i><span class="color-sky-dark">Sky</span></a
          >
          <a href="#" data-change-background="pumpkin"
            ><i class="body-pumpkin"></i
            ><span class="color-pumpkin-dark">Orange</span></a
          >
          <a href="#" data-change-background="yellow3"
            ><i class="body-yellow3"></i
            ><span class="color-yellow3-dark">Yellow</span></a
          >
          <div class="clearfix"></div>
        </div>
        <a
          href="#"
          data-menu="menu-settings"
          class="
            btn btn-full btn-m
            rounded-sm
            bg-highlight
            shadow-xl
            text-uppercase
            font-900
            mt-4
          "
          >Back to Settings</a
        >
      </div>
    </div>
    <!-- Menu Share -->
    <div
      id="menu-share"
      class="menu menu-box-bottom menu-box-detached rounded-m"
      data-menu-height="345"
      data-menu-effect="menu-over"
    >
      <div class="menu-title mt-n1">
        <h1>Share the Love</h1>
        <p class="color-highlight">
          Just Tap the Social Icon. We'll add the Link
        </p>
        <a href="#" class="close-menu"><i class="fa fa-times"></i></a>
      </div>
      <div class="content mb-0">
        <div class="divider mb-0"></div>
        <div class="list-group list-custom-small list-icon-0">
          <a href="#" class="shareToFacebook">
            <i class="font-18 fab fa-facebook color-facebook"></i>
            <span class="font-13">Facebook</span>
            <i class="fa fa-angle-right"></i>
          </a>
          <a href="#" class="shareToTwitter">
            <i class="font-18 fab fa-twitter-square color-twitter"></i>
            <span class="font-13">Twitter</span>
            <i class="fa fa-angle-right"></i>
          </a>
          <a href="#" class="shareToLinkedIn">
            <i class="font-18 fab fa-linkedin color-linkedin"></i>
            <span class="font-13">LinkedIn</span>
            <i class="fa fa-angle-right"></i>
          </a>
          <a href="#" class="shareToWhatsApp">
            <i class="font-18 fab fa-whatsapp-square color-whatsapp"></i>
            <span class="font-13">WhatsApp</span>
            <i class="fa fa-angle-right"></i>
          </a>
          <a href="#" class="shareToMail border-0">
            <i class="font-18 fa fa-envelope-square color-mail"></i>
            <span class="font-13">Email</span>
            <i class="fa fa-angle-right"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import StickyToggle from "@/components/sticky/sticky-toggle.vue";

import { ProfileService, ProfileSettings } from "@/services/profileService";
import { onMounted, reactive, ref, watch } from "vue";

export default {
  name: "AllMenus",
  props: {
    showBackBtn: Boolean,
  },
  components: {
    StickyToggle,
  },

  setup() {
    let currentSettings = reactive({} as ProfileSettings);
    let darkMode = ref(false);

    onMounted(async () => {
      const ps = new ProfileService();
      currentSettings = await ps.getCurrentSettings(); //created not async / needs to be here
      darkMode.value = currentSettings.theme.darkMode;
      window.ww.theme.initAll();
    });

    async function dark(val: boolean) {
      currentSettings.theme.darkMode = darkMode.value = val;
    }

    watch(
      currentSettings,
      async (val: ProfileSettings, oldVal: ProfileSettings) => {
        if (val && oldVal) {
          const ps = new ProfileService();
          await ps.replaceSettings(val);
        }
      },
      { deep: true }
    );

    return {
      currentSettings,
      darkMode,
      dark,
    };
  },
};
</script>