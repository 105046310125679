<template>
  <div
    v-if="msg"
    id="snackbar"
    data-delay="3000"
    data-autohide="true"
    class="snackbar-toast fade hide"
    :class="bgColor"
  >
    <i class="mr-3"><fa :icon="icon" /></i>{{ msg }}
  </div>
</template>
<script lang="ts">
import { Toast } from "bootstrap";
import { Options, Vue } from "vue-class-component";

// interface DisplaySnack {
//   msg: string;
//   icon: string;
//   bgColor: string;
// }
@Options({
  name: "StickySnackbar",
  components: {},
})
export default class StickySnackbar extends Vue {
  public get msg(): string | null {
    const r = this.$app.currentSnack;

    if (!r) {
      return null;
    }
    setTimeout(() => {
      const notificationToast = new Toast(this.$el);
      notificationToast.show();
    });
    return r.msg;
  }

  public get bgColor(): string | null {
    switch (this.$app.currentSnack?.type) {
      case "error":
        return "bg-red2-dark";
      case "warn":
        return "bg-yellow1-dark";
      case "info":
        return "bg-blue2-dark";
      case "success":
        return "bg-green1-dark";
    }
    return null;
  }
  public get icon(): string | null {
    switch (this.$app.currentSnack?.type) {
      case "error":
        return "exclamation";
      case "warn":
        return "exclamation-triangle";
      case "info":
        return "info";
      case "success":
        return "check";
    }
    return null;
  }

  public mounted() {
    console.log("snack mount");
  }
}
</script>
<style lang="scss" scoped>
@import "../../scss/sticky/_variables";
@import "../../scss/components/snackbars";
</style>