
import { Options, Vue } from "vue-class-component";
import LogoIcon from "@/components/icons/logo.vue";
@Options({
  name: "HeaderBar",
  components: {
    LogoIcon,
  },
})
export default class HeaderBar extends Vue {
  private offline = false;
  private backgroundWork = true;

  public get showBackBtn() {
    return this.$router.currentRoute.value.fullPath.lastIndexOf("/") > 0;
  }

  public created() {
    this.offline = !navigator.onLine;
    window.addEventListener("offline", () => (this.offline = true));
    window.addEventListener("online", () => (this.offline = false));
  }
}
