
import StickyToggle from "@/components/sticky/sticky-toggle.vue";

import { ProfileService, ProfileSettings } from "@/services/profileService";
import { onMounted, reactive, ref, watch } from "vue";

export default {
  name: "AllMenus",
  props: {
    showBackBtn: Boolean,
  },
  components: {
    StickyToggle,
  },

  setup() {
    let currentSettings = reactive({} as ProfileSettings);
    let darkMode = ref(false);

    onMounted(async () => {
      const ps = new ProfileService();
      currentSettings = await ps.getCurrentSettings(); //created not async / needs to be here
      darkMode.value = currentSettings.theme.darkMode;
      window.ww.theme.initAll();
    });

    async function dark(val: boolean) {
      currentSettings.theme.darkMode = darkMode.value = val;
    }

    watch(
      currentSettings,
      async (val: ProfileSettings, oldVal: ProfileSettings) => {
        if (val && oldVal) {
          const ps = new ProfileService();
          await ps.replaceSettings(val);
        }
      },
      { deep: true }
    );

    return {
      currentSettings,
      darkMode,
      dark,
    };
  },
};
