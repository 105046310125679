import { get, update } from 'idb-keyval';

export interface LogEntry {
    message: string;
    details?: string;
    data?: any;
    time: number;
    type: 'error' | 'info' | 'warn';
}

export class LogService {
    private static initialized = false;
    private isDebug = process.env.NODE_ENV != "production";
    private static logEntries: Array<LogEntry> = [];

    public constructor() {
        if (LogService.initialized) {
            return;
        }
        get('ls_logs').then(logs => {
            if (logs?.length) {
                LogService.logEntries = JSON.parse(logs);
            }
        });
    }
    public info(msg: string, obj?: any) {
        this.log('info', msg, obj);
    }

    public warn(msg: string, obj?: any) {
        this.log('warn', msg, obj);
    }

    private log(type: 'error' | 'info' | 'warn', msg: string, obj?: any) {
        if (msg?.length && this.isDebug) {
            LogService.logEntries.push({
                time: Date.now(),
                message: msg,
                type: 'info',
                details: obj
            });
            console.info(msg);
            if (obj) {
                console.dir(obj);
            }
        }
    }



    public error(msg: string | any, obj?: any) {
        if (msg) {
            LogService.logEntries.push({
                message: typeof msg == "object" ? msg.toString() : msg,
                details: typeof obj === "string" ? obj : typeof obj === "object" ? obj.toString() : undefined,
                data: new Error(obj),
                time: Date.now(),
                type: 'error'
            });
            update('ls_logs', old => JSON.stringify(LogService.logEntries)).then(val => {
                if (this.isDebug) {
                    console.error(msg);
                    if (obj) {
                        console.dir(obj);
                    }
                }
            });

        }
    }
    public getErrors() {
        return LogService.logEntries.filter(e => e.type === 'error');
    }
    public getLogs() {
        return LogService.logEntries;
    }
}