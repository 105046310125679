
import StickyPage from "@/components/sticky-page.vue";
import { Options, Vue } from "vue-class-component";
import { WWUser } from "@/shared/user";
import { ProfileService, ProfileSettings } from "@/services/profileService";
import UserService from "@/services/userService";

@Options({
  name: "Home",
  components: {
    StickyPage,
  },
})
export default class HomeView extends Vue {
  private us = UserService;
  private ps = new ProfileService();
  private prefs: ProfileSettings | null = null;

  private get showDebug() {
    return this.prefs?.general.debugMode || false;
  }
  private get showLogin() {
    return this.user.signInState == "Unknown";
  }
  public get user(): WWUser {
    return this.us.currentUser;
  }
  public get isWebLaunched() {
    return window.ww.displayMode !== "standalone-ios";
  }
  public get showHomeAd() {
    return false; //TODO: this
  }
  public async created() {
    this.prefs = await this.ps.getCurrentSettings();
  }
  public async loginClick() {
    await this.us.loginPopup();
  }
}
